export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL;
export const SP_PIXEL_ID = process.env.NEXT_PUBLIC_SNAPCHAT_PIXEL;
export const TWT_ADS_ID = process.env.NEXT_PUBLIC_TWITTER_ADS;

export const pageview = () => {
   if (FB_PIXEL_ID && process.env.NEXT_PUBLIC_ENV === 'production') {
      (window as any)?.fbq?.('track', 'PageView');
   }
   if (SP_PIXEL_ID && process.env.NEXT_PUBLIC_ENV === 'production') {
      (window as any)?.snaptr?.('track', 'PAGE_VIEW');
   }
   if (TWT_ADS_ID && process.env.NEXT_PUBLIC_ENV === 'production') {
      (window as any)?.twq?.('track', 'PageView');
   }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}) => {
   (window as any).fbq('track', name, options);
};
