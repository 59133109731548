import { useCallback, useEffect, useRef } from 'react';
import { Translate, useTranslate } from '~/i18n';
import { AngryIcon, CloseIconDark, AngelRightIcon, AngelLeftIcon } from '~/svgs/common';

type MainBodyProps = {
   handleClose: () => void;
   // eslint-disable-next-line no-unused-vars
   updateHeight: (e: HTMLDivElement) => void;
   // eslint-disable-next-line no-unused-vars
   setBody: (feedback: string, emote: string) => void;
};

const feedbackList = [
   'feedback:bugs',
   'feedback:missing features',
   'feedback:confusing',
   'feedback:crashed',
   'feedback:unappealing',
   'feedback:other',
];

const AngryBody = ({ handleClose, updateHeight, setBody }: MainBodyProps) => {
   const { dir, translate } = useTranslate();
   const ref = useRef<any>();

   const update = useCallback(() => {
      updateHeight(ref.current);
   }, [updateHeight]);

   useEffect(() => {
      if (ref) {
         update();
      }
   }, [ref, update]);

   return (
      <div ref={ref} className="flex flex-col items-center p-6 text-center">
         <div className="flex self-start justify-between w-full mb-4">
            <button
               onClick={() => setBody('main', '')}
               className="flex items-center text-gray-500 ltr:-ml-2 rtl:-mr-2 hover:opacity-50 focus:opacity-50 focus:outline-none">
               {dir === 'ltr' ? <AngelLeftIcon /> : <AngelRightIcon />}
               <span>
                  <Translate id="common:back" />
               </span>
            </button>
            <CloseIconDark
               onClick={handleClose}
               className="p-px border rounded-full cursor-pointer hover:opacity-60 focus:opacity-60 border-primary-disabled"
            />
         </div>
         <AngryIcon className="mb-4" />
         <div className="mb-8 text-xl font-bold">
            <Translate id="feedback:sorry" />
         </div>
         <div className="flex flex-col w-full space-y-2">
            {feedbackList.map((feedback, idx) => (
               <button
                  className="px-4 py-2 text-sm border ltr:text-left rtl:text-right border-primary-disabled rounded-default hover:bg-gray-50 focus:outline-none focus:bg-gray-50"
                  onClick={() => setBody('textfield', translate({ id: feedback }))}
                  key={idx}>
                  {<Translate id={feedback} />}
               </button>
            ))}
         </div>
      </div>
   );
};

export default AngryBody;
