import { Fragment, useState } from 'react';
import Dropdown from '~/components/common/dropdown';
import { NextLink, useTranslate } from '~/i18n';
import Router from 'next/router';
import styles from '../styles/styles.module.css';
import classnames from 'classnames';
import { ArrowUpLeft, ArrowUpRight } from '~/svgs/navbar';
import FeedbackContent from '~/components/common/feedback/feedback';
import { useAuth } from '~/auth';
import { UserType } from '~/interfaces/user';

type NavDropdownProps = {
   links: any[];
   icon: any;
   className?: string;
};

const NavDropdown = ({ links, icon, className }: NavDropdownProps) => {
   const [open, setOpen] = useState(false);
   const close = () => setOpen(false);
   const toggle = () => setOpen(prev => !prev);
   const { dir, locale } = useTranslate();
   const [feedbackOpen, setFeedbackOpen] = useState(false);
   const { authenticated } = useAuth<UserType>();

   const handleOpen = () => setFeedbackOpen(true);
   const handleClose = () => setFeedbackOpen(false);

   Router.events.on('routeChangeComplete', () => {
      if (open) close();
   });

   return (
      <Fragment>
         <Dropdown
            toggler={
               <button
                  onClick={toggle}
                  className={classnames(
                     'rounded-full flex justify-center items-center focus:outline-none font-bold leading-none my-5',
                     styles['dropdown-menu'],
                     className
                  )}>
                  <span className="px-2.5 py-1 mx-0.5">{icon}</span>
               </button>
            }
            // Change the width to full if longer text is added
            listClassName="w-52 rtl:right-0 ltr:left-0"
            toggleMenu={close}
            open={open}>
            <div className="p-3">
               <ul className="text-primary-color">
                  {links.map((link, idx) => {
                     const query = link.querylang ? `?lang=${locale}` : '';
                     if (link.item.isPage)
                        return link.item.external ? (
                           <li key={`external-${idx}`}>
                              <a
                                 href={link.href + query}
                                 className="flex items-center px-2 py-3 rounded-default hover:bg-gray-200"
                                 target="_blank"
                                 rel="noreferrer noopener">
                                 <img
                                    src={link.dropDownIcon}
                                    className="object-contain w-5 ltr:mr-2 rtl:ml-2"
                                 />
                                 <span className="flex items-center flex-grow flex-shrink-0">
                                    {link.text}
                                    {dir === 'ltr' ? <ArrowUpRight /> : <ArrowUpLeft />}
                                 </span>
                              </a>
                           </li>
                        ) : (
                           <li key={`internal-${idx}`}>
                              <NextLink
                                 href={link.href}
                                 className="flex items-center px-2 py-3 rounded-default hover:bg-gray-200">
                                 <img
                                    src={link.dropDownIcon}
                                    className="object-contain w-5 ltr:mr-2 rtl:ml-2"
                                 />
                                 <span className="flex-grow flex-shrink-0 block">{link.text}</span>
                              </NextLink>
                           </li>
                        );
                     else if (link.dropDownIcon === '/images/common/happy.svg' && authenticated)
                        return (
                           <li key={idx}>
                              <button
                                 className="focus:outline-none"
                                 onClick={() => {
                                    toggle();
                                    handleOpen();
                                 }}>
                                 <div className="flex items-center px-2 py-3 rounded-default hover:bg-gray-200">
                                    <img
                                       src={link.dropDownIcon}
                                       className="object-contain w-5 ltr:mr-2 rtl:ml-2"
                                    />
                                    <span className="flex-grow flex-shrink-0 block">
                                       {link.text}
                                    </span>
                                 </div>
                              </button>
                           </li>
                        );
                     // Add more potential non page dropdown elements
                  })}
               </ul>
            </div>
         </Dropdown>
         <FeedbackContent open={feedbackOpen} handleClose={handleClose} />
      </Fragment>
   );
};

export default NavDropdown;
