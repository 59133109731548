import { Router, useRouter } from 'next/router';
import { NextLink, Translate, useTranslate } from '~/i18n';
import { AnimateSharedLayout } from 'framer-motion';
import { SearchIcon, DropdownDotsDark } from '~/svgs/navbar';
import NavLink from '~/components/layout/nav-link';
import Link from 'next/link';
import styles from '~/components/layout/styles/styles.module.css';
import Brand from '~/components/layout/header/brand';
import NProgress from 'nprogress';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import UserDropdown from '~/components/layout/header/user-dropdown';
import { useAuth } from '~/auth';
import LangSwitcher from '~/components/common/langSwitcher';
import NavDropdown from '~/components/layout/header/nav-dropdown';
import { jarirDropdownLinks, links } from './header_links';
import JarirSignup from 'components/jarir/modal';
import { RightArrow } from '~/svgs/common';
import {
   navbarLanguageSwitchEvent,
   navbarMyBookingsBtnEvent,
} from '~/utils/mixpanel-events/navbar';
import Dropdown from '~/components/common/dropdown';
import cn from 'classnames';

const JarirWebsiteHeader = ({
   transparentNavbar,
   langHref,
}: {
   transparentNavbar?: boolean;
   langHref?: string;
}) => {
   const { translate, lang, dir } = useTranslate();
   const { authenticated } = useAuth();
   const router = useRouter();
   const [scrolled, setScrolled] = useState(true);
   // const languageUsedInUrl = lang?.split('-')[1];
   const [open, setOpen] = useState(false);
   const isHomePage = router.pathname === `/[lang]`;

   const [openDropdown, setOpenDropdown] = useState(false);
   const closeDropdown = () => setOpenDropdown(false);
   const toggleDropdown = () => setOpenDropdown(prev => !prev);

   useEffect(() => {
      const scroll = () => {
         if (window.scrollY >= 50) {
            setScrolled(true);
         } else setScrolled(false);
      };
      if (transparentNavbar) {
         window.addEventListener('scroll', scroll);
         scroll();
      }
      return () => window.removeEventListener('scroll', scroll);
   }, [transparentNavbar]);

   const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const element = e.currentTarget.search as HTMLInputElement;
      const query = element.value ? `?query=${element.value}` : '';
      router.push(`/${lang}/search${query}`);
   };

   Router.events.on('routeChangeStart', () => NProgress.start());
   Router.events.on('routeChangeComplete', () => NProgress.done());
   Router.events.on('routeChangeError', () => NProgress.done());

   const navbarStyles = scrolled
      ? 'bg-white text-gray-700 fixed top-0 inset-x-0 shadow-md'
      : 'bg-white fixed top-10 inset-x-6 rounded-default';

   const showScrolledOrDefaultStyle = !transparentNavbar || scrolled;

   // Change these flags to convert the navbar to expanded version instead of default
   const expandedMain = false;
   const expandedTablet = false;

   const tabletDropdownLinks = links.filter(link => link.tabletDropdown.whenDefault);
   return (
      <header
         className={classNames(
            'hidden tablet:flex z-60 transition-all duration-150 rounded-xl border-2 border-gray-100',
            expandedTablet ? 'flex-col' : 'flex-row',
            expandedMain ? 'xl:flex-col' : 'xl:flex-row',
            transparentNavbar ? navbarStyles : 'bg-white text-gray-700 sticky top-0 inset-x-0'
         )}>
         <JarirSignup
            open={open}
            onClose={() => setOpen(false)}
            openOtp={setOpen}
            redirectBanner={() => false}
         />
         <div className="flex flex-grow text-black">
            <AnimateSharedLayout>
               <Brand scrolled={!transparentNavbar || scrolled} />
               <nav
                  className={classNames(
                     expandedTablet ? 'hidden' : 'block',
                     expandedMain ? 'xl:hidden' : 'xl:block'
                  )}>
                  <ul className="items-center hidden h-full list-none xl:flex">
                     {links
                        .filter(({ primaryLinkDefault, item }) => primaryLinkDefault && item.isPage)
                        .map(({ item, href, InnerIcon, HomeIcon, text, trackEvent }, idx) => {
                           /* Add other conditions for potential primary non-page nav items and
                              put link.item.isPage in if condition above NavLinnk when doing so */
                           return (
                              <NavLink
                                 key={`l-${idx}`}
                                 href={href}
                                 text={text}
                                 external={item.external}
                                 trackEvent={trackEvent}
                                 linkClassName={
                                    !showScrolledOrDefaultStyle ? styles['nav-link__trans'] : ''
                                 }
                                 icon={
                                    showScrolledOrDefaultStyle
                                       ? InnerIcon && <InnerIcon className="ltr:mr-2 rtl:ml-2" />
                                       : HomeIcon && <HomeIcon className="ltr:mr-2 rtl:ml-2" />
                                 }
                              />
                           );
                        })}
                  </ul>
                  <ul className="flex items-center h-full list-none xl:hidden">
                     {links
                        .filter(
                           ({ item, tabletPrimaryLinkDefault }) =>
                              tabletPrimaryLinkDefault && item.isPage
                        )
                        .map(({ item, href, text, trackEvent }, idx) => {
                           /* Add other conditions for potential primary non-page nav items and
                              put link.item.isPage in if condition above NavLinnk when doing so */
                           return (
                              <NavLink
                                 key={`m-${idx}`}
                                 href={href}
                                 text={text}
                                 external={item.external}
                                 trackEvent={trackEvent}
                                 linkClassName={
                                    !showScrolledOrDefaultStyle ? styles['nav-link__trans'] : ''
                                 }
                              />
                           );
                        })}
                  </ul>
               </nav>
            </AnimateSharedLayout>
            <div className={classNames('flex-grow flex items-center min-w-0 px-3.5')}>
               <div className="relative flex-grow max-w-full">
                  {!isHomePage || (scrolled && isHomePage) ? (
                     <>
                        <label
                           id="search-label"
                           htmlFor="search"
                           className="absolute inset-y-0 flex items-center ltr:left-0 rtl:right-0 ltr:pl-4 rtl:pr-4"
                           aria-label="search">
                           <SearchIcon />
                        </label>
                        <form
                           className="flex"
                           noValidate
                           autoComplete="off"
                           onSubmit={submitSearch}>
                           <input
                              placeholder={translate({ id: 'jarir:search placeholder' })}
                              role="search"
                              id="search"
                              name="search"
                              autoComplete="off"
                              aria-labelledby="search-label"
                              className={classNames(
                                 styles['searchbar_scrolled'],
                                 'flex-grow border border-transparent leading-none text-sm max-w-full h-8 px-2 pt-1 ltr:pl-10 bg-gray-50 rounded-xl',
                                 'rtl:pr-10 shadow-sm focus:shadow-md focus:outline-none focus:ring-1 ring-offset-transparent ring-offset-1 ring-primary-color'
                                 // showScrolledOrDefaultStyle
                                 //    ? styles['searchbar_scrolled']
                                 //    : styles['searchbar_sticked']
                              )}
                           />
                        </form>
                     </>
                  ) : null}
               </div>
            </div>
            <AnimateSharedLayout>
               <nav
                  className={classNames(
                     expandedTablet ? 'hidden' : 'block',
                     expandedMain ? 'xl:hidden' : 'xl:block'
                  )}>
                  <ul className="items-center hidden h-full list-none xl:flex">
                     {links.map((link, idx) => {
                        if (!link.primaryLinkDefault) {
                           const jarirquery = link.jarirLangQuery
                              ? lang == 'sa-en'
                                 ? `${lang}/`
                                 : ''
                              : '';
                           if (!link.fullScreenDropdown.whenDefault) {
                              if (link.item.isPage)
                                 return (
                                    <NavLink
                                       key={`d-${idx}`}
                                       href={
                                          link.jarirLangQuery ? link.href + jarirquery : link.href
                                       }
                                       text={link.text}
                                       external={link.item.external}
                                       trackEvent={link.trackEvent}
                                       linkClassName={
                                          !showScrolledOrDefaultStyle
                                             ? styles['nav-link__trans']
                                             : ''
                                       }
                                    />
                                 );
                              // Add more conditions for future non-page navlink items
                           } else if (authenticated) {
                              return (
                                 <NavDropdown
                                    key={idx}
                                    links={links.filter(
                                       link => link.fullScreenDropdown.whenDefault
                                    )}
                                    icon={<DropdownDotsDark />}
                                    className={
                                       showScrolledOrDefaultStyle
                                          ? 'focus:bg-gray-100 hover:bg-gray-100'
                                          : ''
                                    }
                                 />
                              );
                           }
                        }
                     })}
                  </ul>

                  <ul className="flex items-center h-full list-none xl:hidden">
                     {tabletDropdownLinks.length > 0 && authenticated && (
                        <NavDropdown
                           links={tabletDropdownLinks}
                           icon={<DropdownDotsDark />}
                           className={
                              showScrolledOrDefaultStyle
                                 ? 'focus:bg-gray-100 hover:bg-gray-100'
                                 : ''
                           }
                        />
                     )}
                     {links.map((link, idx) => {
                        if (!link.tabletPrimaryLinkDefault && !link.tabletDropdown.whenDefault) {
                           if (link.item.isPage)
                              return (
                                 <NavLink
                                    key={idx}
                                    href={link.href}
                                    text={link.text}
                                    trackEvent={link.trackEvent}
                                    external={link.item.external}
                                    linkClassName={
                                       !showScrolledOrDefaultStyle ? styles['nav-link__trans'] : ''
                                    }
                                    icon={
                                       showScrolledOrDefaultStyle
                                          ? link.InnerIcon && (
                                               <link.InnerIcon className="ltr:mr-2 rtl:ml-2" />
                                            )
                                          : link.HomeIcon && (
                                               <link.HomeIcon className="ltr:mr-2 rtl:ml-2" />
                                            )
                                    }
                                 />
                              );
                           // Add more conditions for future non-page navlink items
                        }
                     })}
                  </ul>
               </nav>
            </AnimateSharedLayout>
            <div className="flex items-center justify-end ltr:mr-5 rtl:ml-5">
               <div className="mx-3.5 ltr:mb-1.5">
                  {!langHref && (
                     <Fragment>
                        {lang === 'sa-en' && (
                           <LangSwitcher
                              href="/sa-ar"
                              className="-mt-2 text-sm font-arb"
                              trackEvent={navbarLanguageSwitchEvent}
                              text="عربي"
                           />
                        )}
                        {lang === 'sa-ar' && (
                           <LangSwitcher
                              href="/sa-en"
                              trackEvent={navbarLanguageSwitchEvent}
                              className="text-sm font-circular"
                              text="English"
                           />
                        )}
                     </Fragment>
                  )}
                  {langHref && (
                     <Fragment>
                        {lang === 'sa-en' && (
                           <Link href={'/sa-ar' + langHref}>
                              <a
                                 className="-mt-2 text-sm font-arb"
                                 onClick={navbarLanguageSwitchEvent}>
                                 عربي
                              </a>
                           </Link>
                        )}
                        {lang === 'sa-ar' && (
                           <Link href={'/sa-en' + langHref}>
                              <a
                                 className="text-sm font-circular"
                                 onClick={navbarLanguageSwitchEvent}>
                                 English
                              </a>
                           </Link>
                        )}
                     </Fragment>
                  )}
               </div>
               {authenticated ? (
                  <div className="flex items-center rtl:mr-3.5 ltr:ml-3.5">
                     <UserDropdown />
                  </div>
               ) : (
                  <button
                     id="bookings"
                     onClick={() => {
                        setOpen(true);
                        navbarMyBookingsBtnEvent();
                     }}
                     className="text-sm">
                     <Translate id="jarir:bookings" />
                  </button>
               )}
            </div>
            <Dropdown
               toggler={
                  <button
                     onClick={toggleDropdown}
                     className=" flex items-center bg-jarir-primary rounded-lg m-4 px-3 h-12 text-white border-0">
                     <div className="flex items-center ">
                        <div className="ltr:pr-2 rtl:pl-2">
                           <Translate id="jarir:hypro home button" />
                        </div>
                        <div className="pt-1.5">
                           {dir === 'ltr' ? <RightArrow /> : <RightArrow className="rotate-180" />}
                        </div>
                     </div>
                  </button>
               }
               listClassName="border-0 rounded"
               toggleMenu={closeDropdown}
               open={openDropdown}>
               <div className="px-1" style={{ width: '300px' }}>
                  <ul className="text-primary-color">
                     {jarirDropdownLinks.map(({ title, href }) => (
                        <li key={href} className="my-3">
                           <NextLink
                              className={cn(
                                 'rounded hover:bg-gray-light py-2 px-3 cursor-pointer w-full text-sm'
                                 // router.pathname?.includes(href) ? 'text-blue-500' : ''
                              )}
                              href={href}
                              onClick={closeDropdown}>
                              {title}
                           </NextLink>
                        </li>
                     ))}
                  </ul>
               </div>
            </Dropdown>
            {/* <button className=" flex items-center bg-jarir-primary rounded-lg m-4 px-3 h-[38px] text-white border-0">
               <NextLink href="/business" className="flex">
                  <div className="flex items-center">
                     <div className="ltr:pr-2 rtl:pl-2">
                        <Translate id="jarir:hypro home button" />
                     </div>
                     <div className="pt-1.5">{dir === 'ltr' ? <RightArrow /> : <LeftArrow />}</div>
                  </div>
               </NextLink>
            </button>
             */}
         </div>
         <div
            className={classNames(
               expandedTablet ? 'block' : 'hidden',
               expandedMain ? 'xl:block' : 'xl:hidden'
            )}>
            {/* TODO: Add implementation for expanded navbar here */}
         </div>
      </header>
   );
};

export default JarirWebsiteHeader;
