import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Translate } from '~/i18n';
import { CloseIconLight } from '~/svgs/common';
import styles from './styles.module.css';
const OfflineState = () => {
   const [status, setStatus] = useState<'online' | 'offline'>('online');
   const [open, setOpen] = useState(true);
   useEffect(() => {
      const offline = () => setStatus('offline');
      const online = () => {
         setStatus('online');
         setOpen(true);
      };
      window.addEventListener('offline', offline);
      window.addEventListener('online', online);

      return () => {
         window.removeEventListener('online', online);
         window.removeEventListener('offline', offline);
      };
   }, []);
   return status === 'offline' && open ? (
      <motion.div initial={{ height: 0 }} animate={{ height: 50 }} className={styles['wrapper']}>
         <span className="leading-5">
            <Translate id="common:your network is off" />
         </span>
         <span onClick={() => setOpen(false)} role="Close" tabIndex={-1}>
            <CloseIconLight className="border border-primary-disabled p-px rounded-full hover:opacity-60 focus:opacity-60 cursor-pointer" />
         </span>
      </motion.div>
   ) : null;
};
export default OfflineState;
