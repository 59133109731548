// import { DesktopVoucher } from '~/svgs/home-page';
import { NextLink, Translate } from '~/i18n';
import cn from 'classnames';
import { isJarir } from '~/utils/theme';

type HyproBannerProps = {
   isFiltersPage?: boolean;
   small?: boolean;
   footer?: boolean;
   isExperiencesDetailsPage?: boolean;
   trackEvent?: () => void;
};
const HyproBanner = ({ isFiltersPage, small, footer, trackEvent }: HyproBannerProps) => {
   const Tag = NextLink;
   const href = '/business';

   let img = '1.jpg';

   if (isFiltersPage) img = '2.jpg';
   else if (footer) img = '3.jpg';

   return (
      <Tag
         href={href}
         onClick={trackEvent}
         className={cn('flex justify-center', isFiltersPage && 'mt-8')}>
         <div style={{ width: footer ? '320px' : 'auto' }}>
            <div className="relative inline-flex w-full mb-12 overflow-hidden tablet:flex rounded-default">
               <div
                  className={cn(
                     !small &&
                        'lg:justify-start lg:items-center lg:bg-black lg:relative lg:ltr:text-left lg:rtl:text-right',
                     'absolute inset-0 z-10 flex items-end justify-center flex-grow w-full tablet:w-auto p-5 text-center text-white  bg-gradient-to-t from-black to-transparent'
                  )}>
                  <div>
                     <div className={cn('mb-1 text-2xl font-bold', !small && ' md:text-3xl')}>
                        {isJarir ? (
                           <Translate id="home:hypro banner title in jarir" />
                        ) : (
                           <Translate id="home:hypro banner title" />
                        )}
                     </div>
                     <div className={cn(!small && 'md:text-lg')}>
                        {isJarir ? (
                           <Translate id="home:hypro banner subtitle in jarir" />
                        ) : (
                           <Translate id="home:hypro banner subtitle" />
                        )}
                     </div>
                     <div
                        className={cn(
                           'bg-white text-[#0552BC] w-[230px] rounded-default flex h-10 justify-center items-center font-bold mt-4 mx-auto',
                           !small && 'lg:mx-0'
                        )}>
                        <Translate id="home:hypro banner button" />
                     </div>
                  </div>
               </div>
               <div className="relative lg:w-auto w-full h-[285px]">
                  <img
                     className="object-cover w-full h-full"
                     width="450px"
                     height="285px"
                     src={`/images/cta/pro/${img}`}
                  />
                  <div
                     className={cn(
                        !small &&
                           'absolute inset-0 z-10 hidden bg-gradient-to-r from-black to-transparent lg:block'
                     )}></div>
               </div>
            </div>
         </div>
      </Tag>
   );
};

export default HyproBanner;
