import * as React from 'react';
import useTranslate from './useTranslate';
import { ITranslationContext } from './context';

function WithTranslation(): <P extends Partial<ITranslationContext>>(
   WrappedComponent: React.ComponentType<P>
) => ({ ...props }: P) => React.ReactElement<P, string | React.JSXElementConstructor<any>> {
   return function Extend<P extends Partial<ITranslationContext>>(
      WrappedComponent: React.ComponentType<P>
   ) {
      function I18nWithTranslation({ ...props }: P) {
         const translationProps = useTranslate();
         return React.createElement(WrappedComponent, { ...props, ...translationProps });
      }

      return I18nWithTranslation;
   };
}

export default WithTranslation;
