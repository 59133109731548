import { AngelLeftIcon, AngelRightIcon, AngleDownIcon } from '~/svgs/common';
import { Fragment, useState } from 'react';
import Modal, { ModalBody } from '~/components/common/modal';
import { NextLink, Translate, useTranslate } from '~/i18n';
import {
   navbarGetDiscountBtnEvent,
   navbarLogoutBtnEvent,
   navbarLogoutConfirmBtn,
   navbarMyBookingsBtnEvent,
   navbarViewProfileBtnEvent,
   navbarVouchersCreditBtnEvent,
} from '~/utils/mixpanel-events/navbar';

import Button from '~/components/common/button';
import Dropdown from '~/components/common/dropdown';
import Image from 'next/image';
import Router from 'next/router';
import { isJarir } from '~/utils/theme';
import jsCookie from 'js-cookie';
import styles from '../styles/styles.module.css';
import { useAuth } from '~/auth';

// import { useAuth0 } from '@auth0/auth0-react';

const UserDropdown = () => {
   const [open, setOpen] = useState(false);
   const close = () => setOpen(false);
   const toggle = () => setOpen(prev => !prev);
   const { dir } = useTranslate();
   const { logout, user } = useAuth();
   // const { logout: auth0Logout } = useAuth0();
   const [openModal, toggleOpen] = useState(false);

   Router.events.on('routeChangeComplete', close);

   const openLogout = () => toggleOpen(true);
   const closeLogout = () => toggleOpen(false);

   if (!user) return null;

   return (
      <Fragment>
         <Dropdown
            toggler={
               <button onClick={toggle} className={styles['icon']}>
                  <div className="flex items-center gap-2 flex-shrink-0">
                     <div className="flex items-center justify-center w-6 h-6">
                        <Image
                           key={
                              user.thumbnail ||
                              (isJarir ? '/jarir/not_found.svg' : '/images/hy.svg')
                           }
                           src={
                              user.thumbnail ||
                              (isJarir ? '/jarir/not_found.svg' : '/images/hy.svg')
                           }
                           width={24}
                           height={24}
                           layout="fixed"
                           className="object-cover rounded-full"
                        />
                     </div>
                     <AngleDownIcon className="fill-[#52525B]" />
                  </div>
               </button>
            }
            listClassName={styles['dropdown']}
            toggleMenu={close}
            open={open}>
            <div className="p-3 text-sm">
               <NextLink
                  href="/profile"
                  onClick={navbarViewProfileBtnEvent}
                  className="flex items-center p-2 rounded-default hover:bg-gray-200">
                  <div className="flex items-center flex-shrink-0 ltr:mr-2 rtl:ml-2">
                     <Image
                        src={
                           user.thumbnail || (isJarir ? '/jarir/not_found.svg' : '/images/hy.svg')
                        }
                        className="object-cover rounded-full"
                        width={50}
                        height={50}
                        layout="fixed"
                     />
                  </div>
                  <div>
                     <span className={'block text-base font-bold text-primary-color mb-1'}>
                        {user.name}
                     </span>
                     <span className="flex items-center text-sm leading-none text-gray-deminished">
                        <Translate id="common:view profile" />
                        {dir === 'ltr' ? (
                           <AngelRightIcon className="-ml-1 transform scale-50" />
                        ) : (
                           <AngelLeftIcon className="-mr-1 transform scale-50" />
                        )}
                     </span>
                  </div>
               </NextLink>
               <hr className="my-2" />
               <ul className="text-primary-color">
                  <li>
                     <NextLink
                        href="/profile/bookings"
                        onClick={navbarMyBookingsBtnEvent}
                        className="flex items-center px-2 py-3 rounded-default hover:bg-gray-200">
                        <img
                           src="/icons/profile/black-calendar.png"
                           className="object-contain w-5 ltr:mr-2 rtl:ml-2"
                        />
                        <span className="flex-grow block">
                           <Translate id="auth:my bookings" />
                        </span>
                        {dir === 'rtl' ? <AngelLeftIcon /> : <AngelRightIcon />}
                     </NextLink>
                  </li>
                  <li>
                     <NextLink
                        href="/profile/wishlist"
                        className="flex items-center px-2 py-3 rounded-default hover:bg-gray-200">
                        <img
                           src="/icons/profile/heart-filled.png"
                           className="object-contain w-5 ltr:mr-2 rtl:ml-2"
                        />
                        <span className="flex-grow block">
                           <Translate id="auth:my wishlist" />
                        </span>
                        {dir === 'rtl' ? <AngelLeftIcon /> : <AngelRightIcon />}
                     </NextLink>
                  </li>
                  {!isJarir && (
                     <li>
                        <NextLink
                           href="/profile/vouchers"
                           onClick={navbarVouchersCreditBtnEvent}
                           className="flex items-center px-2 py-3 rounded-default hover:bg-gray-200">
                           <img
                              src="/icons/profile/black-ticket.svg"
                              className="object-contain w-5 ltr:mr-2 rtl:ml-2"
                           />
                           <span className="flex-grow block">
                              <Translate id="auth:my vouchers" />
                           </span>
                           {dir === 'rtl' ? <AngelLeftIcon /> : <AngelRightIcon />}
                        </NextLink>
                     </li>
                  )}
                  {!isJarir && (
                     <li>
                        <NextLink
                           href="/profile/referral-code"
                           onClick={navbarGetDiscountBtnEvent}
                           className="flex items-center px-2 py-3 rounded-default hover:bg-gray-200">
                           <img
                              src="/icons/profile/starry-eyed.png"
                              className="object-contain w-5 ltr:mr-2 rtl:ml-2"
                           />
                           <span className="flex-grow block">
                              <Translate
                                 id="auth:get up to 40% discount"
                                 values={{
                                    percentage: user.referral_discount,
                                 }}
                              />
                           </span>
                           {dir === 'rtl' ? <AngelLeftIcon /> : <AngelRightIcon />}
                        </NextLink>
                     </li>
                  )}
               </ul>
               <hr className="my-2" />
               <div>
                  <button
                     onClick={() => {
                        toggle();
                        navbarLogoutBtnEvent();
                        openLogout();
                     }}
                     className="flex items-center w-full px-2 py-3 text-primary-color ltr:text-left rtl:text-right rounded-default hover:bg-gray-200">
                     <img
                        src="/icons/profile/logout.svg"
                        className="object-contain w-5 ltr:mr-2 rtl:ml-2"
                     />
                     <span className="flex-grow block">
                        <Translate id="auth:logout" />
                     </span>
                     {dir === 'rtl' ? <AngelLeftIcon /> : <AngelRightIcon />}
                  </button>
               </div>
            </div>
         </Dropdown>
         <Modal size="md" open={openModal} onClickOutside={closeLogout}>
            <ModalBody className="px-3 py-4">
               <div className="flex justify-center">
                  <div className="flex items-center justify-center w-12 h-12 bg-red-600 rounded-full bg-opacity-15">
                     <svg
                        className="w-8 h-8 text-red-600"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true">
                        <path
                           strokeLinecap="round"
                           strokeLinejoin="round"
                           strokeWidth="2"
                           d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                     </svg>
                  </div>
               </div>
               <p className="my-6 text-xl text-center">
                  <Translate id="auth:confirm logout" />
               </p>
               <div className="flex flex-col items-center justify-center xs:flex-row">
                  <Button
                     onClick={closeLogout}
                     className="w-full px-16 py-2 mb-2 xs:w-auto xs:mb-0"
                     type="secondary">
                     <Translate id="common:cancel" />
                  </Button>
                  <Button
                     onClick={async () => {
                        jsCookie.remove('user_id');
                        navbarLogoutConfirmBtn();
                        // await auth0Logout({
                        //    returnTo: window.location.origin,
                        // });
                        logout();
                     }}
                     className="w-full px-16 py-2 xs:ltr:ml-2 xs:rtl:mr-2 xs:w-auto">
                     <Translate id="common:confirm" />
                  </Button>
               </div>
            </ModalBody>
         </Modal>
      </Fragment>
   );
};

export default UserDropdown;
