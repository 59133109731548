import { Translate } from '~/i18n';

const links = [
   {
      title: <Translate id="footer:about hala yalla" />,
      children: [
         {
            title: <Translate id="footer:about" />,
            href: 'https://halayalla.com/about.php',
            external: true,
         },
         {
            title: <Translate id="footer:contact" />,
            href: '/contact',
            external: false,
         },
         {
            title: <Translate id="footer:blog" />,
            href: 'https://blog.halayalla.com/',
            external: true,
         },
         {
            title: <Translate id="footer:jobs" />,
            href: 'https://www.careers-page.com/supertech-2',
            external: true,
         },
         {
            title: <Translate id="footer:investor relations" />,
            href: 'https://halayalla.com/investorrelations/index.php',
            external: true,
         },
         {
            title: <Translate id="footer:fun startup" />,
            href: 'https://halayalla.com/fun-startup-accelerator-fund.php',
            external: true,
         },
         {
            title: <Translate id="footer:suggest a business" />,
            href: 'mailto:support@halayalla.com?subject=Suggest a Business',
            external: true,
         },
         {
            title: <Translate id="footer:sitemap" />,
            href: '/sitemap',
            external: false,
         },
      ],
   },
   {
      title: <Translate id="footer:business and organizer services" />,
      children: [
         {
            title: <Translate id="footer:all-in-one event ticketing software" />,
            href: '/ticketing',
            external: false,
         },
         {
            title: <Translate id="footer:online svent ticketing sales" />,
            href: '/ticketing/sell-event-tickets-online',
            external: false,
         },
         {
            title: <Translate id="footer:corporate experience packages" />,
            href: '/corporate-experiences-employees',
            external: false,
         },
         {
            title: <Translate id="footer:offline event Ticket sales" />,
            href: '/ticketing/sell-event-tickets-offline',
            external: false,
         },
         {
            title: <Translate id="footer:interactive seat map ticketing" />,
            href: '/ticketing/event-seat-map',
            external: false,
         },
         {
            title: <Translate id="footer:end-to-end experiences booking bystem" />,
            href: '/business',
            external: false,
         },
         {
            title: <Translate id="footer:tourism experiences booking" />,
            href: '/business/tours',
            external: false,
         },
         {
            title: <Translate id="footer:classes & training booking" />,
            href: '/business/classes',
            external: false,
         },
         {
            title: <Translate id="footer:restaurants & dining bookings" />,
            href: '/business/restaurants',
            external: false,
         },
         {
            title: <Translate id="footer:exhibitions & conferences bookings" />,
            href: '/business/exhibitions',
            external: false,
         },
         {
            title: <Translate id="footer:musicians & artists bookings" />,
            href: '/business/musicians',
            external: false,
         },
         {
            title: <Translate id="footer:gym & personal trainers bookings" />,
            href: '/business/gym',
            external: false,
         },
         {
            title: <Translate id="footer:spa, salon & wellness bookings" />,
            href: '/business/spa',
            external: false,
         },
         {
            title: <Translate id="footer:halayalla builder" />,
            href: 'https://halayalla.com/halayalla-business/app-builder.php?lang=en',
            external: true,
         },
         {
            title: <Translate id="footer:sports and esports" />,
            href: 'https://halayalla.com/halayalla-business/sports-esports-tournaments.php',
            external: true,
         },
      ],
   },
   {
      title: <Translate id="footer:legal" />,
      children: [
         // {
         //    title: <Translate id="footer:privacy policy" />,
         //    href: 'https://tickets.halayalla.com/en_US/policy',
         //    external: true,
         // },
         {
            title: <Translate id="footer:terms conditions" />,
            href: 'https://halayalla.com/terms.php',
            external: true,
         },
      ],
   },
];

export default links;
