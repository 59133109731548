import Loader from 'react-loader-spinner';

interface LoadingProps {
   color: string;
   size?: number;
}
const Loading = ({ color, size = 32 }: LoadingProps) => {
   return (
      <div className="flex justify-center">
         <Loader type="Oval" color={color} width={size} height={size} />
      </div>
   );
};
export default Loading;
