import { useRouter } from 'next/router';
import transConfig from '~/translation.json';
import Link from 'next/link';

const { languages } = transConfig;

interface NavLinkProps extends React.HTMLProps<HTMLAnchorElement> {
   className?: string;
   text: string | React.ReactNode;
   trackEvent?: () => void;
}

const LangSwitcher: React.FC<NavLinkProps> = ({ text, href, trackEvent, ...rest }) => {
   const router = useRouter();
   const regex = new RegExp(`^/(${Object.keys(languages).join('|')})`);
   const hrefAs = `${router.asPath.replace(regex, `${href}`)}`;

   const linkHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      trackEvent && trackEvent();
      router.push(hrefAs);
   };

   return (
      <Link href={hrefAs}>
         <a {...rest} onClick={linkHandler}>
            {text}
         </a>
      </Link>
   );
};

export default LangSwitcher;
