import useMedia from '~/hooks/useMedia';
import Modal, { ModalBody } from 'components/common/modal';
import BottomSheet from 'components/common/bottom-sheet';
import Container from 'components/common/container';
import Form from './forms';
import { Fragment, useEffect } from 'react';
import { sentryCaptureMessage } from '~/errors/sentry';
type JarirModalProps = {
   open: boolean;
   onClose?: () => void;
   isBookingPage?: boolean;
   // eslint-disable-next-line no-unused-vars
   openOtp: (active: boolean) => void;
   // eslint-disable-next-line no-unused-vars
   redirectBanner?: (active: boolean) => void;
   order?: any;
   isTickets?: boolean;
   preventRedirect?: boolean;
   otpMode?: boolean;
   modalTitle?: string;
   // eslint-disable-next-line no-unused-vars
   onLogin?: (data: any) => void;
   // eslint-disable-next-line no-unused-vars
   onPaymentFailed?: (data?: any) => void;
};

const LoginModal = ({
   open,
   onClose,
   isBookingPage,
   openOtp,
   order,
   redirectBanner,
   isTickets,
   preventRedirect,
   otpMode,
   modalTitle,
   onLogin,
   onPaymentFailed,
}: JarirModalProps) => {
   const isMobile = useMedia(1100);

   useEffect(() => {
      // Track open
      if (open) {
         sentryCaptureMessage('[Login] Modal opened', { tags: { journey: 'auth' }, level: 'info' });
      }
   }, [open]);

   const handleClose = () => {
      // Track close
      sentryCaptureMessage('[Login] Modal closed', { tags: { journey: 'auth' }, level: 'info' });
      onClose?.();
   };

   return (
      <Fragment>
         {isMobile ? (
            <BottomSheet open={open} toggle={handleClose} showCloseButton>
               <Container>
                  <Form
                     isBookingPage={isBookingPage}
                     openOtp={openOtp}
                     order={order}
                     redirectBanner={redirectBanner}
                     isTickets={isTickets}
                     preventRedirect={preventRedirect}
                     otpMode={otpMode}
                     modalTitle={modalTitle}
                     onLogin={onLogin}
                     onPaymentFailed={onPaymentFailed}
                  />
               </Container>
            </BottomSheet>
         ) : (
            <Modal open={open} size="xs" onClickOutside={handleClose}>
               <ModalBody className="transition-all duration-150 ease-in-out ">
                  <div className="py-4">
                     <Container>
                        <Form
                           isBookingPage={isBookingPage}
                           openOtp={openOtp}
                           order={order}
                           redirectBanner={redirectBanner}
                           isTickets={isTickets}
                           preventRedirect={preventRedirect}
                           otpMode={otpMode}
                           modalTitle={modalTitle}
                           onLogin={onLogin}
                           onPaymentFailed={onPaymentFailed}
                        />
                     </Container>
                  </div>
               </ModalBody>
            </Modal>
         )}
      </Fragment>
   );
};
export default LoginModal;
