import FeedbackButton from '~/components/common/feedback/feedback-button';
import { Translate } from '~/i18n';
import { ExperienceIcon } from '~/svgs/navbar';
import { navbarExpTabEvent } from '~/utils/mixpanel-events/navbar';

export const links = [
   {
      href: '/all-cities/experiences',
      trackEvent: navbarExpTabEvent,
      InnerIcon: ExperienceIcon,
      HomeIcon: ExperienceIcon,
      text: <Translate id="common:experiences" />,
      jarirLangQuery: false,
      item: {
         isPage: true,
         external: false,
         exact: false,
      },
      primaryLinkDefault: true,
      primaryLinkExpanded: false,
      tabletPrimaryLinkDefault: true,
      tabletPrimaryLinkExpanded: false,
      fullScreenDropdown: {
         whenExpanded: false,
         whenDefault: false,
      },
      tabletDropdown: {
         whenExpanded: false,
         whenDefault: false,
      },
      dropDownIcon: '',
   },
   {
      href: '',
      InnerIcon: FeedbackButton,
      HomeIcon: FeedbackButton,
      text: <Translate id="feedback:submit feedback" />,
      jarirLangQuery: false,
      item: {
         isPage: false,
      },
      primaryLinkDefault: false,
      primaryLinkExpanded: false,
      tabletPrimaryLinkDefault: false,
      tabletPrimaryLinkExpanded: false,
      fullScreenDropdown: {
         whenExpanded: false,
         whenDefault: true,
      },
      tabletDropdown: {
         whenExpanded: false,
         whenDefault: true,
      },
      dropDownIcon: '/images/common/happy.svg',
   },
   {
      text: <Translate id="jarir:bookstore" />,
      jarirLangQuery: true,
      href: 'https://www.jarir.com/',
      item: {
         isPage: true,
         external: true,
         exact: false,
      },
      primaryLinkDefault: false,
      primaryLinkExpanded: false,
      tabletPrimaryLinkDefault: false,
      tabletPrimaryLinkExpanded: false,
      fullScreenDropdown: {
         whenExpanded: false,
         whenDefault: false,
      },
      tabletDropdown: {
         whenExpanded: false,
         whenDefault: false,
      },
      dropDownIcon: '',
   },
];

export const jarirDropdownLinks: any[] = [
   {
      title: <Translate id="jarir:sell event tickets with jarir" />,
      href: '/ticketing',
   },
   {
      title: <Translate id="jarir:sell your experiences on jarir" />,
      href: '/business',
   },
];
