import { useRouter } from 'next/router';
import Image from '~/components/common/image';
import { NextLink, useTranslate } from '~/i18n';
import { JarirRedBrand } from '~/svgs/jarir';
import { isJarir } from '~/utils/theme';

const Brand = ({ scrolled }: { scrolled?: boolean }) => {
   return (
      <NextLink className="flex items-center justify-center flex-shrink-0" href="">
         <div className="rtl:pr-5 ltr:pl-5 rtl:pl-3.5 ltr:pr-3.5 py-4 hover:scale-110 transform transition-transform duration-150 relative">
            <BrandLogo scrolled={scrolled} />
         </div>
      </NextLink>
   );
};
export const BrandLogo = ({ scrolled, isFooter }: { scrolled?: boolean; isFooter?: boolean }) => {
   const { query } = useRouter();
   const { locale } = useTranslate();

   return isJarir ? (
      scrolled ? (
         <div>
            <div className="hidden xs:block xs:mt-2" aria-label="Jarir Tickets">
               <JarirRedBrand style={{ height: 40, width: 200 }} />
            </div>
            <div className="xs:hidden" aria-label="Jarir Tickets">
               <JarirRedBrand style={{ height: 29, width: 116 }} />
            </div>
         </div>
      ) : (
         <div className="mt-2" aria-label="Jarir Tickets">
            <JarirRedBrand style={{ height: 40, width: 200 }} />
         </div>
      )
   ) : query.slug == 'americas-cup' && !isFooter ? (
      <Image alt="Americas Cup" src="/images/common/americas-cup.svg" height={64} width={62} />
   ) : (
      <Image
         alt="webook.com logo"
         imageWrapperClassName="flex"
         src={`/icons/wbk-prev-hy-${locale}.svg`}
         height={28}
         width={62}
      />
   );
};
export default Brand;
