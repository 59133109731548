import {
   AgoolFooterIcon,
   GSAFooterIcon,
   JazeelFooterIcon,
   KafuFooterIcon,
   KooraFooterIcon,
   SFAFooterIcon,
   SharekFooterIcon,
} from '~/svgs/footer';
import { ArrowUpLeft, ArrowUpRight } from '~/svgs/navbar';
import { FacebookIcon, InstagramIcon, SnapchatIcon, TwitterIcon } from '~/svgs/footer';
import { NextLink, Translate, useTranslate } from '~/i18n';

import Container from '~/components/common/container';
import HyproBanner from '~/components/home-page/hypro-banner';
import Image from 'next/image';
import { homepageSellYourExpFooterBtnEvent } from '~/utils/mixpanel-events/homepage';
import links from './footer_links';
import { publicUrl } from '~/utils/publicUrl';
import styles from './footer.module.css';

const socials = [
   { href: 'https://www.facebook.com/halayalla/', icon: <FacebookIcon />, label: 'Facebook' },
   { href: 'https://twitter.com/halayalla/', icon: <TwitterIcon />, label: 'Twitter' },
   { href: 'https://www.snapchat.com/add/hala_yalla', icon: <SnapchatIcon />, label: 'Snapchat' },
   { href: 'https://www.instagram.com/halayalla/', icon: <InstagramIcon />, label: 'Instagram' },
   // { href: 'https://tiktok.com', icon: <TikTokIcon /> },
];

const WebsiteFooter = () => {
   const { dir, lang, translate, locale } = useTranslate();

   return (
      <footer className="block max-w-full mx-auto">
         <Container>
            <div className="px-3 py-12 border-t md:px-6 border-gray-50">
               <div className="flex flex-col-reverse lg:flex-row">
                  {/*  */}
                  <div className="flex flex-col items-center pr-16 rtl:pl-16 ltr:border-r rtl:border-l border-gray-50">
                     <div className="mb-12">
                        <Image
                           alt="webook.com logo"
                           src={`/icons/wbk-prev-hy-${locale}.svg`}
                           height={86}
                           width={200}
                        />
                     </div>

                     <span className="block mb-4">
                        <Translate id="footer:download the app" />
                     </span>

                     <div className="flex mb-6 space-x-1 rtl:space-x-reverse">
                        <a
                           target="_blank"
                           rel="noreferrer noopener"
                           aria-label={translate({ id: 'common:download appstore' })}
                           href="https://apps.apple.com/us/app/webook-com-fun-things-to-do/id6468667896">
                           <Image
                              key={`appstore${lang}`}
                              src={publicUrl(
                                 lang === 'sa-ar' ? 'images/appstore_ar.svg' : 'images/appstore.svg'
                              )}
                              alt="HalaYalla  Appstore"
                              width={140}
                              height={41}
                           />
                        </a>
                        <a
                           target="_blank"
                           rel="noreferrer noopener"
                           aria-label={translate({ id: 'common:download playstore' })}
                           href="https://play.google.com/store/apps/details?id=com.webook.android">
                           <Image
                              key={`playstore${lang}`}
                              src={publicUrl(
                                 lang === 'sa-ar'
                                    ? 'images/playstore-ar.svg'
                                    : 'images/playstore.svg'
                              )}
                              alt="HalaYalla Playstore"
                              width={140}
                              height={41}
                           />
                        </a>
                     </div>
                     <span className="block mb-3">
                        <Translate id="footer:follow us" />
                     </span>
                     <div className="flex mb-10 space-x-3 rtl:space-x-reverse">
                        {socials.map(({ href, icon, label }) => (
                           <a
                              href={href}
                              target="_blank"
                              aria-label={label}
                              rel="noreferrer noopener"
                              key={href}
                              className={styles['icon']}>
                              {icon}
                           </a>
                        ))}
                     </div>
                     <span className="text-primary-light">
                        &copy; {new Date().getFullYear()} <Translate id="footer:made with love" />
                     </span>
                  </div>
                  <div className="container flex-grow pl-8 rtl:pr-8 ">
                     <div className="flex flex-wrap mb-16">
                        {links.map((link, index) => (
                           <div key={index} className="w-full mb-8 sm:w-1/2 xl:w-1/3">
                              <div>
                                 <span className="block px-4 mb-6 text-lg font-bold">
                                    {link.title}
                                 </span>
                                 <ul className="space-y-3 text-sm list-none text-primary-color">
                                    {(link.children as any[]).map(
                                       (item: any, child_idx: number) => (
                                          <li key={child_idx}>
                                             {item.external && (
                                                <a
                                                   className="relative inline-flex items-center px-4 py-1 rounded-full hover:bg-gray-100"
                                                   href={item.href}
                                                   target="_blank"
                                                   rel="noreferrer noopener">
                                                   <span className="">
                                                      {item.title}
                                                      {dir === 'ltr' ? (
                                                         <ArrowUpRight className="inline-block" />
                                                      ) : (
                                                         <ArrowUpLeft className="inline-block" />
                                                      )}
                                                   </span>
                                                </a>
                                             )}
                                             {!item.external && (
                                                <NextLink
                                                   className="relative inline-flex items-center px-4 py-1 rounded-full hover:bg-gray-100"
                                                   href={item.href}>
                                                   {item.title}
                                                </NextLink>
                                             )}
                                          </li>
                                       )
                                    )}
                                 </ul>
                              </div>
                           </div>
                        ))}
                        <div className="hidden md:block">
                           <HyproBanner
                              small={true}
                              footer={true}
                              trackEvent={homepageSellYourExpFooterBtnEvent}
                           />
                        </div>
                     </div>

                     <div className="py-6 text-center border-t border-gray-50 sm:ltr:text-left sm:rtl:-text-right">
                        <span className="block mb-6 text-lg font-bold">
                           <Translate id="footer:icons title" />
                        </span>
                        <div>
                           <div className="flex flex-col flex-wrap items-center space-y-6 sm:items-start sm:space-y-0 sm:flex-row sm:space-x-12 rtl:space-x-reverse">
                              <a
                                 target="_blank"
                                 rel="noreferrer noopener"
                                 aria-label="Kafu games"
                                 href="https://kafugames.com">
                                 <KafuFooterIcon className="my-2" />
                              </a>
                              <a
                                 target="_blank"
                                 rel="noreferrer noopener"
                                 aria-label="Agool"
                                 href="https://agool.halayalla.com">
                                 <AgoolFooterIcon />
                              </a>
                              <a
                                 target="_blank"
                                 rel="noreferrer noopener"
                                 aria-label="Jazeel"
                                 href="https://jazeel.halayalla.com">
                                 <JazeelFooterIcon />
                              </a>
                              <a
                                 target="_blank"
                                 rel="noreferrer noopener"
                                 aria-label="Koora"
                                 href="https://koora.halayalla.com">
                                 <KooraFooterIcon />
                              </a>
                              <a
                                 target="_blank"
                                 rel="noreferrer noopener"
                                 href="https://sharek.sa"
                                 aria-label="Sharek">
                                 <SharekFooterIcon />
                              </a>
                              <a
                                 target="_blank"
                                 rel="noreferrer noopener"
                                 aria-label="Sports For All"
                                 href="https://sportsforall.com.sa">
                                 <SFAFooterIcon />
                              </a>
                              <a
                                 target="_blank"
                                 aria-label="GSA Live"
                                 rel="noreferrer noopener"
                                 href="https://gsa.live">
                                 <GSAFooterIcon />
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Container>
      </footer>
   );
};

export default WebsiteFooter;
