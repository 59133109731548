import { Translate } from '~/i18n';
import { AngryIcon, HappyIcon, MehIcon, CloseIconDark } from '~/svgs/common';
import Axios from '~/utils/axios';
import { UserType } from '~/interfaces/user';
import { useAuth } from '~/auth';
import { useCallback, useEffect, useRef } from 'react';
import { sentryCaptureException } from '~/errors/sentry';

type MainBodyProps = {
   handleClose: () => void;
   // eslint-disable-next-line no-unused-vars
   updateHeight: (e: HTMLDivElement) => void;
   // eslint-disable-next-line no-unused-vars
   setBody: (feedback: string, emote: string) => void;
};

const MainBody = ({ handleClose, updateHeight, setBody }: MainBodyProps) => {
   const { user } = useAuth<UserType>();
   const ref = useRef<any>();

   const update = useCallback(() => {
      updateHeight(ref.current);
   }, [updateHeight]);

   useEffect(() => {
      if (ref) {
         update();
      }
   }, [ref, update]);

   const sendFeedback = async () => {
      try {
         const values = {
            message: 'Default message: User is happy!',
            feedback_emoji: 'happy',
            email: user?.email,
            id: user?.user_id,
         };
         await Axios.post('https://app.halayalla.com/api/send_feedback', values);
      } catch (error: any) {
         sentryCaptureException(error);
         console.error(error);
      }
   };

   return (
      <div ref={ref} className="flex flex-col items-center p-6 text-center">
         <CloseIconDark
            onClick={handleClose}
            className="self-end p-px mb-4 border rounded-full cursor-pointer hover:opacity-60 focus:opacity-60 border-primary-disabled"
         />
         <span className="block mb-8 text-xl font-bold tracking-tight">
            <Translate id="feedback:feedback title" />
         </span>
         <span className="mb-8 text-primary-light">
            <Translate id="feedback:feedback subtitle" />
         </span>
         <div>
            <div className="flex justify-center space-x-10 text-sm small:space-x-16 rtl:space-x-reverse text-primary-light feedback">
               <button
                  className="focus:outline-none"
                  onClick={() => {
                     sendFeedback();
                     setBody('happy', '');
                  }}>
                  <HappyIcon />
                  <span className="block mt-1">
                     <Translate id="feedback:love it" />
                  </span>
               </button>
               <button className="focus:outline-none" onClick={() => setBody('meh', '')}>
                  <MehIcon />
                  <span className="block mt-1">
                     <Translate id="feedback:meh" />
                  </span>
               </button>
               <button className="focus:outline-none" onClick={() => setBody('angry', '')}>
                  <AngryIcon />
                  <span className="block mt-1">
                     <Translate id="feedback:angry" />
                  </span>
               </button>
            </div>
         </div>
      </div>
   );
};

export default MainBody;
