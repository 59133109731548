import { Translate, useTranslate } from '~/i18n';
import { useCallback, useEffect, useRef } from 'react';
import { CloseIconDark, HappyIcon } from '~/svgs/common';
import { FacebookIcon, CopyIcon, WhatsappIcon, TwitterIcon } from '~/svgs/feedback';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import Image from 'next/image';
import { publicUrl } from '~/utils/publicUrl';
import Button from '../button';
import { useState } from 'react';
import { isJarir } from '~/utils/theme';

type HappyBodyProps = {
   handleClose: () => void;
   // eslint-disable-next-line no-unused-vars
   updateHeight: (e: HTMLDivElement) => void;
};

const HappyBody = ({ handleClose, updateHeight }: HappyBodyProps) => {
   const { lang, translate } = useTranslate();
   const [copied, setCopied] = useState(false);
   const ref = useRef<any>();

   const update = useCallback(() => {
      updateHeight(ref.current);
   }, [updateHeight]);

   useEffect(() => {
      if (ref) {
         update();
      }
   }, [ref, update]);

   const copyToClipboard = () => {
      if (copied) return;
      if (navigator.clipboard) {
         navigator.clipboard?.writeText(
            isJarir ? `https://tickets.jarir.com/${lang}` : `https://www.halayalla.com/${lang}`
         );
         setCopied(true);
         setTimeout(() => {
            setCopied(false);
         }, 1500);
      }
   };

   return (
      <div ref={ref} className="flex flex-col items-center p-6 text-center">
         <CloseIconDark
            onClick={handleClose}
            className="self-end p-px mb-4 border rounded-full cursor-pointer hover:opacity-60 focus:opacity-60 border-primary-disabled"
         />
         <HappyIcon className="mb-4" />
         <div className="mb-8 text-xl font-bold">
            <Translate id="feedback:awww!" />
         </div>
         <div className="mb-2">
            <Translate id="feedback:share love" />
         </div>
         <div className="flex mb-4 gap-x-4">
            <button
               onClick={() => copyToClipboard()}
               className="relative rounded-full focus:outline-none focus:ring-1 focus:ring-black">
               {copied ? (
                  <span>
                     <span className="absolute px-2 py-1 text-xs text-center bg-white border whitespace-nowrap -top-7 -right-4 rounded-xl">
                        <Translate id="common:copied" />
                     </span>
                     <div className="hover:opacity-60">
                        <CopyIcon />
                     </div>
                  </span>
               ) : (
                  <div className="hover:opacity-60">
                     <CopyIcon />
                  </div>
               )}
            </button>
            <TwitterShareButton
               className="flex items-center justify-center rounded-full focus:outline-none focus:ring-1 focus:ring-black"
               url={
                  isJarir
                     ? `https://tickets.jarir.com/${lang}`
                     : `https://www.halayalla.com/${lang}`
               }
               // title={'https://www.halayalla.com/sa-en'}
               hashtags={isJarir ? ['jarirtickets'] : ['halyalla']}
               related={isJarir ? ['JarirBookstore'] : ['halayalla']}>
               <span className="hover:opacity-60">
                  <TwitterIcon />
               </span>
            </TwitterShareButton>
            <FacebookShareButton
               className="flex items-center justify-center rounded-full focus:outline-none focus:ring-1 focus:ring-black"
               url={
                  isJarir
                     ? `https://tickets.jarir.com/${lang}`
                     : `https://www.halayalla.com/${lang}`
               }
               hashtag={isJarir ? '#jarirtickets' : '#halayalla'}
               // quote={'https://www.halayalla.com/sa-en'}
            >
               <span className="hover:opacity-60">
                  <FacebookIcon />
               </span>
            </FacebookShareButton>
            <WhatsappShareButton
               className="flex items-center justify-center rounded-full focus:outline-none focus:ring-1 focus:ring-black"
               url={
                  isJarir
                     ? `https://tickets.jarir.com/${lang}`
                     : `https://www.halayalla.com/${lang}`
               }
               // title={'https://www.halayalla.com/sa-en'}
            >
               <span className="hover:opacity-60">
                  <WhatsappIcon />
               </span>
            </WhatsappShareButton>
         </div>
         {!isJarir && (
            <>
               <div className="mb-2">
                  <Translate id="feedback:try app" />
               </div>

               <div className="flex justify-center mb-8 flex-nowrap gap-x-3">
                  <a
                     className="rounded-lg focus:outline-none focus:opacity-70 hover:opacity-70"
                     target="_blank"
                     rel="noreferrer noopener"
                     aria-label="Download from the appstore"
                     href="https://apps.apple.com/us/app/webook-com-fun-things-to-do/id6468667896">
                     <Image
                        key={`appstore${lang}`}
                        src={publicUrl(
                           lang === 'sa-ar' ? 'images/appstore_ar.svg' : 'images/appstore.svg'
                        )}
                        alt={translate({ id: 'seo:home alt' })}
                        width={140}
                        height={41}
                     />
                  </a>
                  <a
                     className="rounded-lg rtl:ml-2 ltr:mr-2 focus:outline-none focus:opacity-70 hover:opacity-70"
                     target="_blank"
                     rel="noreferrer noopener"
                     aria-label="Download from the playstore"
                     href="https://play.google.com/store/apps/details?id=com.webook.android">
                     <Image
                        key={`playstore${lang}`}
                        src={publicUrl(
                           lang === 'sa-ar' ? 'images/playstore-ar.svg' : 'images/playstore.svg'
                        )}
                        alt={translate({ id: 'seo:home alt' })}
                        width={140}
                        height={41}
                     />
                  </a>
               </div>
            </>
         )}
         <Button onClick={handleClose} className="w-full">
            <Translate id="feedback:ok thanks" />
         </Button>
      </div>
   );
};

export default HappyBody;
