import { DropdownDotsDark, SearchIcon } from '~/svgs/navbar';
import { Fragment, useEffect, useState } from 'react';
import { Router, useRouter } from 'next/router';
import {
   navbarLanguageSwitchEvent,
   navbarMyBookingsBtnEvent,
} from '~/utils/mixpanel-events/navbar';

import { AnimateSharedLayout } from 'framer-motion';
import Brand from './brand';
import { BusinessDropdown } from '~/components/home-page/business-dropdown';
import FeedbackButton from '~/components/common/feedback/feedback-button';
import GuestDropdown from './guest-dropdown';
import JarirSignup from 'components/jarir/modal';
import LangSwitcher from '~/components/common/langSwitcher';
import Link from 'next/link';
import NProgress from 'nprogress';
import NavDropdown from './nav-dropdown';
import NavLink from '../nav-link';
import UserDropdown from './user-dropdown';
import classNames from 'classnames';
import { links } from './header_links';
import styles from '../styles/styles.module.css';
import { useAuth } from '~/auth';
import { useTranslate } from '~/i18n';

const WebsiteHeader = ({
   transparentNavbar,
   langHref,
   hideSearchbar,
   hasTopBanner,
}: {
   transparentNavbar?: boolean;
   langHref?: string;
   hideSearchbar?: boolean;
   hasTopBanner?: boolean;
}) => {
   const { translate, lang } = useTranslate();
   const { authenticated, user } = useAuth();
   const router = useRouter();
   const [scrolled, setScrolled] = useState(false);
   const [open, setOpen] = useState(false);
   const [openSearch, setOpenSearch] = useState(false);
   const isHomePage = router.pathname === `/[lang]`;

   const isAuthed = authenticated && !!user;

   useEffect(() => {
      const scroll = () => {
         if (window.scrollY >= 50) {
            setScrolled(true);
         } else {
            setScrolled(false);
            setOpenSearch(false);
         }
      };
      if (transparentNavbar) {
         window.addEventListener('scroll', scroll);
         scroll();
      }
      return () => window.removeEventListener('scroll', scroll);
   }, [transparentNavbar]);

   const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const element = e.currentTarget.search as HTMLInputElement;
      const query = element.value ? `?query=${element.value}` : '';
      router.push(`/${lang}/search${query}`);
   };

   Router.events.on('routeChangeStart', () => NProgress.start());
   Router.events.on('routeChangeComplete', () => NProgress.done());
   Router.events.on('routeChangeError', () => NProgress.done());

   const navbarStyles = scrolled
      ? `bg-white text-gray-700 fixed top-0 inset-x-0 shadow-md ${
           hasTopBanner ? 'top-28' : 'top-0'
        }`
      : hasTopBanner
      ? 'bg-white text-gray-700 fixed top-28 mt-1.5 inset-x-6 rounded-default border-2 border-gray-100'
      : 'bg-white text-gray-700 fixed top-5 inset-x-6 rounded-default border-2 border-gray-100';

   const showScrolledOrDefaultStyle = !transparentNavbar || scrolled;

   // Make variables like this for NavDropdown components
   const tabletDropdownLinks = links.filter(link => link.tabletDropdown.whenDefault);

   return (
      <header
         className={classNames(
            'hidden tablet:flex z-60 transition-all duration-150',
            transparentNavbar
               ? navbarStyles
               : `bg-white text-gray-700 sticky inset-x-0 ${hasTopBanner ? 'top-28' : 'top-0'}`
         )}>
         <JarirSignup
            open={open}
            onClose={() => setOpen(false)}
            openOtp={setOpen}
            redirectBanner={() => false}
         />
         <div
            className={classNames(
               'flex flex-grow',
               scrolled && 'container mx-auto px-4',
               !transparentNavbar && 'bg-white container mx-auto'
            )}>
            <AnimateSharedLayout>
               <Brand />
               <nav>
                  {/* Default main navlink links on large screens */}
                  <ul className="items-center hidden h-full list-none xl:flex">
                     {links.map(
                        (
                           {
                              primaryLinkDefault,
                              item,
                              href,
                              text,
                              InnerIcon,
                              HomeIcon,
                              trackEvent,
                           },
                           idx
                        ) => {
                           /* Add other conditions for potential primary non-page nav items and
                              put link.item.isPage in if condition above NavLinnk when doing so */
                           if (primaryLinkDefault && item.isPage) {
                              return (
                                 <NavLink
                                    key={idx}
                                    href={href}
                                    trackEvent={trackEvent}
                                    text={text}
                                    external={item.external}
                                    className="mx-2.5"
                                    linkClassName={
                                       !showScrolledOrDefaultStyle ? styles['nav-link__trans'] : ''
                                    }
                                    icon={
                                       showScrolledOrDefaultStyle
                                          ? InnerIcon && <InnerIcon className="ltr:mr-2 rtl:ml-2" />
                                          : HomeIcon && <HomeIcon className="ltr:mr-2 rtl:ml-2" />
                                    }
                                 />
                              );
                           }
                        }
                     )}
                  </ul>
                  {/* Default main navlink links on smaller screens */}
                  <ul className="flex items-center h-full list-none xl:hidden">
                     {links.map(
                        (
                           {
                              tabletPrimaryLinkDefault,
                              item,
                              href,
                              text,
                              InnerIcon,
                              HomeIcon,
                              trackEvent,
                           },
                           idx
                        ) => {
                           /* Add other conditions for potential primary non-page nav items and
                              put link.item.isPage in if condition above NavLinnk when doing so */
                           if (tabletPrimaryLinkDefault && item.isPage) {
                              return (
                                 <NavLink
                                    key={idx}
                                    trackEvent={trackEvent}
                                    href={href}
                                    text={text}
                                    external={item.external}
                                    className="mx-2.5"
                                    linkClassName={
                                       !showScrolledOrDefaultStyle ? styles['nav-link__trans'] : ''
                                    }
                                    icon={
                                       showScrolledOrDefaultStyle
                                          ? InnerIcon && <InnerIcon className="ltr:mr-2 rtl:ml-2" />
                                          : HomeIcon && <HomeIcon className="ltr:mr-2 rtl:ml-2" />
                                    }
                                 />
                              );
                           }
                        }
                     )}
                  </ul>
               </nav>
            </AnimateSharedLayout>
            <div className={classNames('flex-grow flex items-center px-3.5')}>
               <div className="relative flex-grow max-w-full py-2">
                  {(!isHomePage || (scrolled && isHomePage)) && !hideSearchbar ? (
                     <>
                        <label
                           id="search-label"
                           htmlFor="search"
                           className={classNames(
                              'absolute inset-y-0 flex items-center ltr:left-0 rtl:right-0 ltr:pl-4 rtl:pr-4',
                              openSearch ? 'cursor-auto' : 'cursor-pointer'
                           )}
                           aria-label="search"
                           onClick={() => setOpenSearch(true)}>
                           <SearchIcon />
                        </label>
                        <form
                           className={classNames(
                              'flex transition-all',
                              openSearch ? 'w-full' : 'w-0 pointer-events-none'
                           )}
                           noValidate
                           autoComplete="off"
                           onSubmit={submitSearch}>
                           <input
                              placeholder={translate({ id: 'common:search placeholder' })}
                              role="search"
                              id="search"
                              name="search"
                              autoComplete="off"
                              aria-labelledby="search-label"
                              className={classNames(
                                 styles['searchbar'],
                                 'flex-grow border border-transparent leading-none text-sm max-w-full h-8 px-2 pt-1 ltr:pl-10',
                                 'rtl:pr-10 shadow-sm focus:shadow-md focus:outline-none focus:ring-1 ring-offset-transparent ring-offset-1 ring-primary-color',
                                 showScrolledOrDefaultStyle
                                    ? styles['searchbar_scrolled']
                                    : styles['searchbar_sticked']
                              )}
                           />
                        </form>
                     </>
                  ) : null}
               </div>
            </div>
            <AnimateSharedLayout>
               <nav>
                  {/* Default secondary navlink links on large screens */}
                  <ul className="items-center hidden h-full list-none xl:flex">
                     {links.map((link, idx) => {
                        if (!link.primaryLinkDefault) {
                           if (!link.fullScreenDropdown.whenDefault) {
                              if (link.item.isPage)
                                 return (
                                    <NavLink
                                       key={idx}
                                       href={link.href}
                                       text={link.text}
                                       trackEvent={link.trackEvent}
                                       external={link.item.external}
                                       className="mx-2.5"
                                       linkClassName={
                                          !showScrolledOrDefaultStyle
                                             ? styles['nav-link__trans']
                                             : ''
                                       }
                                       icon={
                                          showScrolledOrDefaultStyle
                                             ? link.InnerIcon && (
                                                  <link.InnerIcon className="ltr:mr-2 rtl:ml-2" />
                                               )
                                             : link.HomeIcon && (
                                                  <link.HomeIcon className="ltr:mr-2 rtl:ml-2" />
                                               )
                                       }
                                    />
                                 );
                              else if (link.HomeIcon === FeedbackButton && isAuthed)
                                 return (
                                    <li key={idx} className="mx-3.5">
                                       <link.HomeIcon />
                                    </li>
                                 );
                              // Add more conditions for future non-page navlink items
                           } else {
                              return (
                                 <NavDropdown
                                    key={idx}
                                    links={links.filter(
                                       link => link.fullScreenDropdown.whenDefault
                                    )}
                                    icon={
                                       showScrolledOrDefaultStyle ? (
                                          <DropdownDotsDark />
                                       ) : (
                                          <DropdownDotsDark />
                                       )
                                    }
                                    className={
                                       showScrolledOrDefaultStyle
                                          ? 'focus:bg-gray-100 hover:bg-gray-100'
                                          : ''
                                    }
                                 />
                              );
                           }
                        }
                     })}
                  </ul>
                  {/* Default secondary navlink links on smaller screens */}
                  <ul className="flex items-center h-full list-none xl:hidden">
                     {tabletDropdownLinks.length > 0 && (
                        <NavDropdown
                           links={tabletDropdownLinks}
                           icon={
                              showScrolledOrDefaultStyle ? (
                                 <DropdownDotsDark />
                              ) : (
                                 <DropdownDotsDark />
                              )
                           }
                           className={
                              showScrolledOrDefaultStyle
                                 ? 'focus:bg-gray-100 hover:bg-gray-100'
                                 : ''
                           }
                        />
                     )}
                     {links.map((link, idx) => {
                        if (!link.tabletPrimaryLinkDefault && !link.tabletDropdown.whenDefault) {
                           if (link.item.isPage)
                              return (
                                 <NavLink
                                    key={idx}
                                    href={link.href}
                                    trackEvent={link.trackEvent}
                                    text={link.text}
                                    external={link.item.external}
                                    className="mx-2.5"
                                    linkClassName={
                                       !showScrolledOrDefaultStyle ? styles['nav-link__trans'] : ''
                                    }
                                    icon={
                                       showScrolledOrDefaultStyle
                                          ? link.InnerIcon && (
                                               <link.InnerIcon className="ltr:mr-2 rtl:ml-2" />
                                            )
                                          : link.HomeIcon && (
                                               <link.HomeIcon className="ltr:mr-2 rtl:ml-2" />
                                            )
                                    }
                                 />
                              );
                           // Add more conditions for future non-page navlink items
                        }
                     })}
                  </ul>
               </nav>
            </AnimateSharedLayout>
            <div className={classNames('flex items-center', scrolled && 'whitespace-nowrap ')}>
               <BusinessDropdown />
            </div>
            <div
               className={classNames(
                  'flex items-center justify-end ltr:mr-5 rtl:ml-5 min-w-0',
                  scrolled && 'whitespace-nowrap'
               )}>
               <div className="mx-3.5">
                  {!langHref && (
                     <Fragment>
                        {lang === 'sa-en' && (
                           <LangSwitcher
                              href="/sa-ar"
                              className="-mt-2 font-arb"
                              text="عربي"
                              trackEvent={navbarLanguageSwitchEvent}
                           />
                        )}
                        {lang === 'sa-ar' && (
                           <LangSwitcher
                              href="/sa-en"
                              className="font-circular"
                              trackEvent={navbarLanguageSwitchEvent}
                              text="English"
                           />
                        )}
                     </Fragment>
                  )}

                  {langHref && (
                     <Fragment>
                        {lang === 'sa-en' && (
                           <Link href={'/sa-ar' + langHref}>
                              <a className="-mt-2 font-arb" onClick={navbarLanguageSwitchEvent}>
                                 عربي
                              </a>
                           </Link>
                        )}
                        {lang === 'sa-ar' && (
                           <Link href={'/sa-en' + langHref}>
                              <a className="font-circular" onClick={navbarLanguageSwitchEvent}>
                                 English
                              </a>
                           </Link>
                        )}
                     </Fragment>
                  )}
               </div>
               {isAuthed ? (
                  <div className="flex items-center rtl:mr-3.5 ltr:ml-3.5">
                     <UserDropdown />
                  </div>
               ) : (
                  <GuestDropdown
                     authFunction={() => {
                        setOpen(true);
                        navbarMyBookingsBtnEvent();
                     }}
                  />
               )}
            </div>
         </div>
      </header>
   );
};

export default WebsiteHeader;
