import { AppProps } from 'next/app';
import Layout from '~/components/layout';
import { getLang } from '~/utils/translate';
import { initGTM, initHOTJAR } from '~/utils/analytics';
import { useEffect } from 'react';
import { AuthProvider } from '~/auth';
import { TranslationProvider } from '~/i18n';
import { DefaultSeo, SocialProfileJsonLd } from 'next-seo';
import transConfig from '~/translation.json';
import SwiperCore, { Pagination, Navigation, Autoplay, Thumbs, FreeMode } from 'swiper';
import { NextPageContext, NextComponentType } from 'next';
import Head from 'next/head';
import OfflineState from '~/components/offline-state';
import { AnimateSharedLayout } from 'framer-motion';
import FacebookPixel from '~/components/common/pixel-tracker';
import { BASE_URL, isJarir } from '~/utils/theme';
import Favicon from '~/components/favicon';
import 'react-spring-bottom-sheet/dist/style.css';
import '../css/fonts.css';
import '../css/tailwind.css';
import '../css/web.css';
import '../css/animations.css';
import '../css/nprogress.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/free-mode';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { initMixpanel } from '~/utils/mixpanel-events/init';
import ErrorBoundary from '~/components/error/ErrorBoundary';
import Script from 'next/script';

const { languages } = transConfig;

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                              NEXTJS IS AWESOME                             */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
interface CustomAppProps extends AppProps {
   Component: NextComponentType<NextPageContext, any> & {
      hideLayout?: boolean;
      hideHeader?: boolean;
      smallFooter?: boolean;
      transparentNavbar?: boolean;
      hideSearchbar?: boolean;
      preventPageViews?: boolean;
   };
}
SwiperCore.use([Pagination, Navigation, Autoplay, Thumbs, FreeMode]);

// export function reportWebVitals(metric: NextWebVitalsMetric) {
//    const body = JSON.stringify(metric);
//    const url = '/api/vitals';

//    if (navigator.sendBeacon) navigator.sendBeacon(url, body);
//    else fetch(url, { body, method: 'POST', keepalive: true });
// }

const App = ({ Component, pageProps, router }: CustomAppProps) => {
   /*
    * Make sure to only use it when necessary as this function will run on every page
    * thats why we are using analytics in here
    **/
   useEffect(() => {
      initGTM();
      initHOTJAR();
      initMixpanel();

      console.log('%c HalaYalla', 'color:yellow; margin:auto; font-size:38px;');
      console.log(
         '%c Inspecting? we feel flattered :)',
         'color:orange; margin:auto; font-size:18px;'
      );
      console.log(
         "%c If you find any bug, please don't hesitate to contact us",
         'color:orange; margin:auto; font-size:15px;'
      );
      console.log(
         '%c Thank you for inspecting, enjoy the journey with us ❤️',
         'color:orange; margin:auto; font-size:15px;'
      );
   }, []);

   /* ---------------------- Application current language ---------------------- */
   const lang = getLang(router);

   // Manipulating html element for lang and dir attributes
   useEffect(() => {
      const langs = languages as Record<string, string>;
      const dir = langs[lang];
      document.documentElement.lang = `${lang?.split('-')[1]}`;
      document.documentElement.dir = dir;
      document.body.dir = dir;
   }, [lang]);

   let translations = null;

   try {
      translations = require(`~/translations/${lang}`).default;
      // eslint-disable-next-line no-empty
   } catch (error) {}

   const {
      hideLayout,
      hideHeader,
      smallFooter,
      transparentNavbar,
      hideSearchbar,
      preventPageViews,
   } = Component;

   return (
      <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_CAPTCHA_KEY}>
         <TranslationProvider
            lang={lang}
            locale={lang?.split('-')?.[1]}
            translations={translations}>
            {isJarir ? (
               <SocialProfileJsonLd
                  type="Organization"
                  name="Jarir Tickets"
                  url="https://tickets.jarir.com"
                  sameAs={[
                     'https://twitter.com/jarir',
                     'https://www.instagram.com/jarir',
                     'https://www.facebook.com/jarir',
                  ]}
               />
            ) : (
               <SocialProfileJsonLd
                  type="Organization"
                  name="Hala Yalla"
                  url="https://halayalla.com"
                  sameAs={[
                     'https://twitter.com/halayalla',
                     'https://www.instagram.com/halayalla',
                     'https://www.facebook.com/halayalla',
                  ]}
               />
            )}
            <DefaultSeo
               description=""
               defaultTitle={
                  isJarir ? translations.jarir['jarir tickets'] : translations.common['hala yalla']
               }
               titleTemplate={`%s | ${
                  isJarir ? translations.jarir['jarir tickets'] : translations.common['hala yalla']
               }`}
               canonical={BASE_URL + router.asPath}
               dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_ENV !== 'production'}
               dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_ENV !== 'production'}
               twitter={{
                  cardType: 'summary',
                  site: isJarir ? '@Jarir' : '@HalaYalla',
               }}
               openGraph={{
                  site_name: isJarir
                     ? translations.jarir['jarir tickets']
                     : translations.common['hala yalla'],
                  title: isJarir
                     ? translations.jarir['jarir tickets']
                     : translations.common['hala yalla'],
                  description: translations.seo['home description'],
                  locale: lang?.split('-')[1],
                  type: 'website',
                  url: BASE_URL + router.asPath,
                  images: [
                     {
                        url: isJarir
                           ? BASE_URL + '/jarir/mainfest/logo.png'
                           : BASE_URL + '/images/hy-bann.jpg',
                        alt: translations.seo['home alt'],
                     },
                  ],
               }}
            />
            <Head>
               <meta name="facebook-domain-verification" content="puyewqml9boqfcx7510voanrcsa25z" />

               <meta
                  name="viewport"
                  content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
               />
               {!isJarir && (
                  <Script src="https://www.googleoptimize.com/optimize.js?id=OPT-PXXK7G2"></Script>
               )}
            </Head>
            <Favicon />
            <ErrorBoundary>
               <FacebookPixel preventPageViews={preventPageViews}>
                  <AuthProvider
                     afterLoginTo={`/${lang}/profile`}
                     onAuthTo={`/${lang}`}
                     loginPath="/">
                     <Layout
                        hideHeader={hideHeader}
                        hideLayout={hideLayout}
                        smallFooter={smallFooter}
                        hideSearchbar={hideSearchbar}
                        transparentNavbar={transparentNavbar}>
                        <AnimateSharedLayout>
                           <Component {...pageProps} />
                        </AnimateSharedLayout>
                        <OfflineState />
                     </Layout>
                  </AuthProvider>
               </FacebookPixel>

               {/* ------------------------------- App styling ------------------------------ */}
               {/* this makes sure that main take the full available height of the broweser */}
               <style jsx global>{`
                  #__next {
                     display: flex;
                     flex-direction: column;
                     min-height: 100vh;
                  }
                  #__next main {
                     flex: 1;
                     overflow-x: hidden;
                     background-color: white;
                  }
               `}</style>
            </ErrorBoundary>
         </TranslationProvider>
      </GoogleReCaptchaProvider>
   );
};

export default App;
