import { FC } from 'react';
import classNames from 'classnames';
const Container: FC<React.HTMLProps<HTMLDivElement>> = ({ children, className, ...props }) => {
   return (
      <div className={classNames('container mx-auto px-4', className)} {...props}>
         {children}
      </div>
   );
};
export default Container;
