import common from './common.json';
import validation from './validation.json';
import home from './home.json';
import footer from './footer.json';
import benifits from './benifits.json';
import booking from './booking.json';
import auth from './auth.json';
import profile from './profile.json';
import seo from './seo.json';
import business from './business.json';
import groups from './groups.json';
import vouchers from './vouchers.json';
import innerbusiness from './inner-business.json';
import about from './about.json';
import feedback from './feedback.json';
import contact from './contact.json';
import filters from './filters.json';
import jarir from './jarir.json';
import review from './review.json';
import jarirterms from './jarir-terms.json';
import organizers from './organizers.json';
import jarirseo from './jarir-seo.json';
import jarirauth from './jarir-auth.json';
import { isJarir } from '~/utils/theme';
import navbar from './navbar.json';
import tickets from './tickets.json';
import newsletter from './newsletter.json';
import founding from './founding.json';
import topcarousel from './top-carousel.json';
import businessategory from './business-categories.json';
import ticketing from './ticketing.json';
import corporateExperiences from './corporate-experinces.json';
import sitemap from './sitemap.json';
import jarirTicketing from './jarir-ticketing.json';

const namespaces = {
   common,
   validation,
   home,
   footer,
   benifits,
   booking,
   auth,
   profile,
   seo: isJarir ? jarirseo : seo,
   business,
   groups,
   vouchers,
   innerbusiness,
   about,
   feedback,
   contact,
   filters,
   jarir,
   review,
   jarirterms,
   organizers,
   jarirauth,
   navbar,
   tickets,
   newsletter,
   founding,
   topcarousel,
   businessategory,
   ticketing: isJarir ? jarirTicketing : ticketing,
   corporateExperiences,
   sitemap,
};
export default namespaces;
