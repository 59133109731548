import { Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import JarirWebsiteHeader from '~/custom/jarir/layout/header/website-header';
import { isJarir } from '~/utils/theme';
import MobileHeader from './mobile-header';
import WebsiteHeader from './website-header';
import WebookHeader from './webook-header';
interface HeaderProps {
   langHref?: string;
   transparentNavbar?: boolean;
   hideSearchbar?: boolean;
}

const Header = ({ transparentNavbar, langHref, hideSearchbar }: HeaderProps) => {
   const router = useRouter();
   const isBusinessPage = router.pathname === `/[lang]/business`;
   const isTicketingPage = router.pathname === `/[lang]/ticketing`;
   const [showWebookHeader, setShowWebookHeader] = useState(
      router.pathname === `/[lang]` || isBusinessPage || isTicketingPage
   );
   return (
      <Fragment>
         {isJarir ? (
            <JarirWebsiteHeader transparentNavbar={transparentNavbar} langHref={langHref} />
         ) : (
            <>
               {showWebookHeader ? (
                  <WebookHeader setShowWebookHeader={setShowWebookHeader} />
               ) : null}
               <WebsiteHeader
                  hideSearchbar={hideSearchbar}
                  transparentNavbar={transparentNavbar}
                  langHref={langHref}
                  hasTopBanner={showWebookHeader}
               />
            </>
         )}
         <MobileHeader
            showWebookHeader={!isJarir && showWebookHeader}
            setShowWebookHeader={setShowWebookHeader}
         />
      </Fragment>
   );
};

export default Header;
