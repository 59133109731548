import { AngleDownIcon } from '~/svgs/common';
import Dropdown from '~/components/common/dropdown';
import { Translate } from '~/i18n';
import { UserIcon } from '~/svgs/navbar';
import styles from '../styles/styles.module.css';
import { useState } from 'react';

const GuestDropdown = ({ authFunction }: any) => {
   const [open, setOpen] = useState(false);
   const close = () => setOpen(false);
   const toggle = () => setOpen(prev => !prev);

   return (
      <>
         <Dropdown
            toggler={
               <button
                  onClick={toggle}
                  className="rounded-3xl shadow-md p-2 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none">
                  <div className="flex items-center gap-2 flex-shrink-0">
                     <div className="flex items-center justify-center w-6 h-6 bg-[#DEE2E6] rounded-full">
                        <UserIcon className="fill-[#6E757C] w-4" />
                     </div>
                     <AngleDownIcon className="fill-[#52525B]" />
                  </div>
               </button>
            }
            listClassName={styles['guest-dropdown']}
            toggleMenu={close}
            open={open}>
            <div
               className="my-1 rounded hover:bg-gray-light py-2 px-3 cursor-pointer w-full text-sm"
               onClick={authFunction}>
               <Translate id="auth:log in" />
            </div>
            <div
               className="my-1 rounded hover:bg-gray-light py-2 px-3 cursor-pointer w-full text-sm"
               onClick={authFunction}>
               <Translate id="auth:signup" />
            </div>
         </Dropdown>
      </>
   );
};

export default GuestDropdown;
