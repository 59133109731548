// import { useRouter } from 'next/router';
import { useRef } from 'react';
import Image from 'next/image';
import { NextLink, Translate, useTranslate } from '~/i18n';
// import { AngelLeftIcon, AngelRightIcon } from '~/svgs/common';
import { BrandLogo } from './brand';
import { isJarir } from '~/utils/theme';
import { JarirRedBrand } from '~/svgs/jarir';
import Link from 'next/link';
import classname from 'classnames';
import LangSwitcher from '~/components/common/langSwitcher';
import { Fragment } from 'react';
import { useState } from 'react';
// import { Drawer } from '../../drawer';
import { MoreIcon } from '~/svgs/footer';
import dynamic from 'next/dynamic';
import { navbarLanguageSwitchEvent } from '~/utils/mixpanel-events/navbar';
import { CouponLottieAnimation } from '~/components/lottie/coupon';
import Button from '~/components/common/button';
import { useRouter } from 'next/router';
import { CloseIcon, ArrowDownIcon } from '~/svgs/common';
import { motion } from 'framer-motion';

const Drawer = dynamic(() => import('../../drawer'), { ssr: false });

const Banner = () => {
   const [closed, setClosed] = useState(false);
   const { lang } = useTranslate();
   const router = useRouter();

   const close = () => setClosed(true);

   if (closed) return null;

   return (
      <div className="flex mini:flex-nowrap flex-wrap w-full bg-[#EBEBEB] px-4 py-3 gap-2 mini:gap-4">
         <div className="flex items-start gap-2 mini:w-4/6">
            <CouponLottieAnimation className="!w-12 flex-shrink-0" />
            <div className="flex flex-col">
               <b className="text-sm">
                  <Translate id="common:sell your event tickets faster" />
               </b>
               <span className="text-xs">
                  <Translate id="common:get premium online ticketing service" />
               </span>
            </div>
            <button aria-label="close" onClick={close} className="ltr:ml-4 rtl:mr-4 mini:hidden">
               <CloseIcon />
            </button>
         </div>
         <div className="flex items-center justify-center flex-1 flex-shrink-0 mini:justify-end">
            <Button
               onClick={() => router.push(`/${lang}/ticketing/sell-event-tickets-online`)}
               className="block flex-shrink-0 !py-1 shadow-none mt-0.5 border-none text-sm !rounded-full bg-transparent bg-gradient-to-r from-[#41E297] to-[#3bb6b6] !w-fit !p-1">
               <div className="flex items-center rounded-full text-center !w-fit bg-[#EBEBEB] h-7 pb-1 mt-[1px] px-4 text-black">
                  <Translate id="common:contact" />
               </div>
            </Button>
            <button
               aria-label="close"
               onClick={close}
               className="hidden ltr:ml-4 rtl:mr-4 mini:block">
               <CloseIcon />
            </button>
         </div>
      </div>
   );
};

const WebookBanner = ({
   setShowWebookHeader,
}: {
   setShowWebookHeader?: (state: boolean) => void;
}) => {
   const { lang, locale } = useTranslate();
   const [expand, setExpand] = useState(false);
   const router = useRouter();
   const isHomePage = router.pathname === `/[lang]`;
   const isTicketingPage = router.pathname === `/[lang]/ticketing`;

   return (
      <div
         className="relative flex flex-col tablet:hidden items-center justify-center text-center w-full text-white pb-5"
         style={{
            background:
               'linear-gradient(135deg, #1E3D56 17.1%, rgba(31, 62, 88, 0.00) 51.46%), #000',
         }}>
         <a href={`https://webook.com/${locale}`} target="_blank" rel="noreferrer">
            <Image priority src="/images/weebook/hy-to-weebook.svg" width={385} height={105} />
         </a>
         <button
            aria-label="close"
            onClick={() => setShowWebookHeader?.(false)}
            className="absolute ltr:right-2 rtl:left-2 top-2">
            <CloseIcon className="text-white" />
         </button>
         <h1 className="py-3 text-lg">
            <Translate id="home:webook rebrand title" />
         </h1>
         <button
            onClick={() => setExpand(prev => !prev)}
            className="flex items-center justify-end focus:outline-none">
            <ArrowDownIcon
               className={classname(
                  'w-2 mt-1 ltr:ml-1 rtl:mr-1 transform transition-all duration-150',
                  expand && 'rotate-180'
               )}
            />
            <Image
               priority
               className={classname(
                  'w-2 mt-3 transform transition-all duration-150',
                  expand && 'rotate-180'
               )}
               src="/images/common/down-arrow.svg"
               width={10}
               height={10}
            />
         </button>
         <motion.div
            transition={{
               duration: 0.15,
               easings: 'easeInOut',
            }}
            initial={{ height: 0 }}
            animate={{ height: expand ? 'auto' : 0 }}
            className="flex-grow overflow-hidden">
            <div className="flex flex-col gap-4 items-center justify-center px-4">
               <p className={`text-sm text-center ${isHomePage ? 'py-4' : ''}`}>
                  {!isHomePage ? (
                     <Translate id="home:webook business page description" />
                  ) : (
                     <Translate id="home:webook rebrand subtitle" />
                  )}
               </p>
               {!isHomePage ? (
                  <a
                     href={
                        isTicketingPage
                           ? `/${lang}/ticketing#request-callback-join-today`
                           : `https://pro.halayalla.com/${locale}/signup`
                     }
                     target={isTicketingPage ? '_self' : '_blank'}
                     rel="noreferrer">
                     <button className="px-2 -mt-1 py-1 bg-[#0552bc] rounded-md">
                        {isTicketingPage ? (
                           <Translate id="business:request a call back" />
                        ) : (
                           <Translate id="business:signup now" />
                        )}
                     </button>
                  </a>
               ) : null}
            </div>
         </motion.div>
      </div>
   );
};

const MobileHeader = ({
   langHref,
   classNames,
   showWebookHeader,
   setShowWebookHeader,
}: {
   langHref?: string;
   classNames?: string;
   showWebookHeader?: boolean;
   setShowWebookHeader?: (state: boolean) => void;
}) => {
   const headerRef = useRef<HTMLDivElement>(null);
   const [showDrawer, setShowDrawer] = useState(false);
   const { lang } = useTranslate();

   const openDrawer = () => setShowDrawer(true);
   const closeDrawer = () => setShowDrawer(false);

   return (
      <div
         className={`sticky inset-x-0 -top-1 block mobile-header z-70 tablet:hidden ${
            classNames || ''
         }`}>
         {isJarir ? (
            <Banner />
         ) : showWebookHeader ? (
            <WebookBanner setShowWebookHeader={setShowWebookHeader} />
         ) : null}
         <div
            ref={headerRef}
            className={classname(
               ' text-primary-color bg-white transition duration-150 footer',
               isJarir ? ' px-4 py-3.5' : ' px-4 py-2'
            )}>
            <div className="flex items-center justify-center">
               <div className="w-16 xs:w-20">
                  <button onClick={openDrawer}>
                     <MoreIcon />
                  </button>
               </div>
               <Drawer onClose={closeDrawer} isOpen={showDrawer} />

               <div className="flex justify-center flex-grow">
                  <NextLink href="">{isJarir ? <JarirRedBrand /> : <BrandLogo />}</NextLink>
               </div>
               <div className="flex items-center justify-end">
                  <div className="mx-3.5 ltr:mb-1.5">
                     {!langHref && (
                        <Fragment>
                           {lang === 'sa-en' && (
                              <LangSwitcher
                                 href="/sa-ar"
                                 trackEvent={navbarLanguageSwitchEvent}
                                 className={classname('font-arb', isJarir && 'text-black')}
                                 text="عربي"
                              />
                           )}
                           {lang === 'sa-ar' && (
                              <LangSwitcher
                                 href="/sa-en"
                                 trackEvent={navbarLanguageSwitchEvent}
                                 className={classname('font-circular', isJarir && 'text-black')}
                                 text="English"
                              />
                           )}
                        </Fragment>
                     )}

                     {langHref && (
                        <Fragment>
                           {lang === 'sa-en' && (
                              <Link href={'/sa-ar' + langHref}>
                                 <a
                                    className="text-sm font-arb"
                                    onClick={navbarLanguageSwitchEvent}>
                                    عربي
                                 </a>
                              </Link>
                           )}
                           {lang === 'sa-ar' && (
                              <Link href={'/sa-en' + langHref}>
                                 <a
                                    className="text-sm font-circular"
                                    onClick={navbarLanguageSwitchEvent}>
                                    English
                                 </a>
                              </Link>
                           )}
                        </Fragment>
                     )}
                  </div>
               </div>

               {/* <NextLink
               href="/notifications"
               className="flex items-center justify-center w-8 h-8 bg-opacity-50 rounded-full bg-primary-color hover:bg-opacity-100">
               <NotificationIcon />
            </NextLink> */}
            </div>
         </div>
      </div>
   );
};
export default MobileHeader;
