export const isJarir = process.env.NEXT_PUBLIC_WEB_DESIGN === 'jarir';
const getJarirURL = () => {
   switch (process.env.NEXT_PUBLIC_ENV) {
      case 'production':
         return 'https://tickets.jarir.com';

      default:
         return 'https://jarir-web.halayalla.rocks';
   }
};

const getHYURL = () => {
   switch (process.env.NEXT_PUBLIC_ENV) {
      case 'production':
         return 'https://halayalla.com';
      case 'staging':
         return 'https://hy-web-staging.halayalla.rocks';
      case 'testing':
         return 'https://hy-web-test.halayalla.rocks';
      default:
         return 'http://localhost:3000';
   }
};

export const BASE_URL = process.env.NEXT_PUBLIC_WEB_DESIGN === 'jarir' ? getJarirURL() : getHYURL();
