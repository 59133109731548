import { Fragment } from 'react';
// import MobileFooter from './mobile-footer';
import WebsiteFooter from './website-footer';
import SmallFooter from '~/components/common/custom-layout/footer';
import { isJarir } from '~/utils/theme';
import JarirWebsiteFooter from '~/custom/jarir/layout/footer/website-footer';

const Footer = ({ smallFooter }: { smallFooter?: boolean }) => {
   return (
      <Fragment>
         {/* <MobileFooter /> */}
         {smallFooter ? (
            <div className="hidden tablet:block">
               <SmallFooter />
            </div>
         ) : isJarir ? (
            <JarirWebsiteFooter />
         ) : (
            <WebsiteFooter />
         )}
      </Fragment>
   );
};

export default Footer;
