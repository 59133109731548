import Head from 'next/head';
import { Fragment } from 'react';
import { isJarir } from '~/utils/theme';

const Favicon = () => {
   return (
      <Head>
         {isJarir ? (
            <Fragment>
               <meta name="application-name" content="Jarir Tickets" />
               <meta name="apple-mobile-web-app-capable" content="yes" />
               <meta name="apple-mobile-web-app-status-bar-style" content="default" />
               <meta name="apple-mobile-web-app-title" content="Jarir Tickets" />
               <meta name="format-detection" content="telephone=no" />
               <meta name="mobile-web-app-capable" content="yes" />
               <meta name="theme-color" content="#ce342b" />
               <link
                  rel="apple-touch-icon"
                  sizes="57x57"
                  href="/jarir/favicon/apple-icon-57x57.png"
               />
               <link
                  rel="apple-touch-icon"
                  sizes="60x60"
                  href="/jarir/favicon/apple-icon-60x60.png"
               />
               <link
                  rel="apple-touch-icon"
                  sizes="72x72"
                  href="/jarir/favicon/apple-icon-72x72.png"
               />
               <link
                  rel="apple-touch-icon"
                  sizes="76x76"
                  href="/jarir/favicon/apple-icon-76x76.png"
               />
               <link
                  rel="apple-touch-icon"
                  sizes="114x114"
                  href="/jarir/favicon/apple-icon-114x114.png"
               />
               <link
                  rel="apple-touch-icon"
                  sizes="120x120"
                  href="/jarir/favicon/apple-icon-120x120.png"
               />
               <link
                  rel="apple-touch-icon"
                  sizes="144x144"
                  href="/jarir/favicon/apple-icon-144x144.png"
               />
               <link
                  rel="apple-touch-icon"
                  sizes="152x152"
                  href="/jarir/favicon/apple-icon-152x152.png"
               />
               <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href="/jarir/favicon/apple-icon-180x180.png"
               />
               <link
                  rel="icon"
                  type="image/png"
                  sizes="192x192"
                  href="/jarir/favicon/android-icon-192x192.png"
               />
               <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/jarir/favicon/favicon-32x32.png"
               />
               <link
                  rel="icon"
                  type="image/png"
                  sizes="96x96"
                  href="/jarir/favicon/favicon-96x96.png"
               />
               <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/jarir/favicon/favicon-16x16.png"
               />
               <link rel="manifest" href="/jarir/favicon/manifest.json" />
               <meta name="msapplication-TileColor" content="#ffffff" />
               <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
               <meta name="theme-color" content="#ffffff" />
            </Fragment>
         ) : (
            <Fragment>
               <meta name="application-name" content="HalaYalla" />
               <meta name="apple-mobile-web-app-capable" content="yes" />
               <meta name="apple-mobile-web-app-status-bar-style" content="default" />
               <meta name="apple-mobile-web-app-title" content="HalaYalla" />
               <meta name="format-detection" content="telephone=no" />
               <meta name="mobile-web-app-capable" content="yes" />
               <meta name="theme-color" content="#212529" />

               <link rel="apple-touch-icon" sizes="192x192" href="/icons/hy-192x192.png" />
               <link
                  rel="apple-touch-startup-image"
                  href="/icons/hy-sm.png"
                  media="(max-device-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
               />
               <link
                  rel="apple-touch-startup-image"
                  href="/icons/hy-md.png"
                  media="(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
               />
               <link
                  rel="apple-touch-startup-image"
                  href="/icons/hy-big.png"
                  media="(min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
               />
               <link rel="manifest" href="/manifest.json" />
            </Fragment>
         )}
      </Head>
   );
};
export default Favicon;
