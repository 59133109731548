import { NextLink, Translate, useTranslate } from '~/i18n';

import { AngleDownIcon } from '~/svgs/common';
import Dropdown from '../common/dropdown';
import { RockOn } from '~/svgs/navbar';
import cn from 'classnames';
import { navbarHYBusinessTabEvent } from '~/utils/mixpanel-events/navbar';
import { useRouter } from 'next/router';
import { useState } from 'react';

export const BusinessDropdown = () => {
   const router = useRouter();
   const { translate } = useTranslate();
   const [open, setOpen] = useState(false);
   const close = () => setOpen(false);
   const toggle = () => setOpen(prev => !prev);
   const isBusinessRoute = router.pathname === '/[lang]/business';
   const isTicketingRoute = router.pathname === '/[lang]/ticketing';

   const links = [
      {
         title: translate({ id: 'navbar:sell your experience' }),
         href: '/business',
      },
      // {
      //    title: translate({ id: 'navbar:online svent ticketing sales' }),
      //    href: '/ticketing/sell-event-tickets-online',
      // },
      // {
      //    title: translate({ id: 'navbar:corporate experience packages' }),
      //    href: '/corporate-experiences-employees',
      // },
      // {
      //    title: translate({ id: 'navbar:offline event Ticket sales' }),
      //    href: '/ticketing/sell-event-tickets-offline',
      // },
      // {
      //    title: translate({ id: 'navbar:interactive seat map ticketing' }),
      //    href: '/ticketing/event-seat-map',
      // },
      {
         title: translate({ id: 'navbar:sell your events tickets' }),
         href: '/ticketing',
      },
      // {
      //    title: translate({ id: 'navbar:tourism experiences booking' }),
      //    href: '/business/tours',
      // },
      // {
      //    title: translate({ id: 'navbar:classes & training booking' }),
      //    href: '/business/classes',
      // },
      // {
      //    title: translate({ id: 'navbar:restaurants & dining bookings' }),
      //    href: '/business/restaurants',
      // },
      // {
      //    title: translate({ id: 'navbar:exhibitions & conferences bookings' }),
      //    href: '/business/exhibitions',
      // },
      // {
      //    title: translate({ id: 'navbar:musicians & artists bookings' }),
      //    href: '/business/musicians',
      // },
      // {
      //    title: translate({ id: 'navbar:gym & personal trainers bookings' }),
      //    href: '/business/gym',
      // },
      // {
      //    title: translate({ id: 'navbar:spa, salon & wellness bookings' }),
      //    href: '/business/spa',
      // },
   ];

   return (
      <Dropdown
         toggler={
            <button
               onClick={toggle}
               className={cn(
                  'flex items-center justify-between mx-2 gap-1.5 focus:outline-none',
                  isBusinessRoute || isTicketingRoute ? 'border-b border-primary-color' : ''
               )}>
               <div className="flex items-center flex-grow">
                  <div className="h-10">
                     <RockOn className="mt-1.5" />
                  </div>
                  <span className="text-sm ltr:ml-2 rtl:mr-2">
                     <Translate id="common:hala yalla for business" />
                  </span>
               </div>
               <AngleDownIcon />
            </button>
         }
         listClassName="border-0 rounded"
         toggleMenu={close}
         open={open}>
         <div className="px-1" style={{ width: '345px' }}>
            <ul className="text-primary-color">
               {links.map(({ title, href }) => (
                  <li key={href} className="my-3">
                     <NextLink
                        className={cn(
                           'rounded hover:bg-gray-light py-2 px-3 cursor-pointer w-full text-sm'
                           // router.pathname?.includes(href) ? 'text-blue-500' : ''
                        )}
                        href={href}
                        onClick={() => {
                           toggle();
                           href === '/business' && navbarHYBusinessTabEvent();
                        }}>
                        {title}
                     </NextLink>
                  </li>
               ))}
            </ul>
         </div>
      </Dropdown>
   );
};
