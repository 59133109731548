import { CloseIconDark } from '~/svgs/common';
import { CircleCheck } from '~/svgs/feedback';
import { Translate } from '~/i18n';
import Button from '../button';
import { useCallback, useEffect, useRef } from 'react';

type SubmittedBodyProps = {
   handleClose: () => void;
   // eslint-disable-next-line no-unused-vars
   updateHeight: (e: HTMLDivElement) => void;
};

const SubmittedBody = ({ handleClose, updateHeight }: SubmittedBodyProps) => {
   const ref = useRef<any>();

   const update = useCallback(() => {
      updateHeight(ref.current);
   }, [updateHeight]);

   useEffect(() => {
      if (ref) {
         update();
      }
   }, [ref, update]);

   return (
      <div ref={ref} className="flex flex-col items-center p-6 text-center">
         <CloseIconDark
            onClick={handleClose}
            className="self-end mb-4 border rounded-full cursor-pointer hover:opacity-60 focus:opacity-60 border-primary-disabled"
         />
         <div className="m-auto mb-4">
            <CircleCheck />
         </div>
         <div className="mb-8 text-xl font-bold">
            <Translate id="feedback:appreciate" />
         </div>
         <div className="mb-5">
            <Translate id="feedback:do best" />
         </div>
         <Button onClick={handleClose} className="w-full">
            <Translate id="feedback:ok thanks" />
         </Button>
      </div>
   );
};

export default SubmittedBody;
