import { Mixpanel } from '../init';

export const homepagePageViewEvent = () => {
   Mixpanel.track('Homepage - Page View');
};

export const homepageSearchViewEvent = (query: any) => {
   Mixpanel.track('Homepage - Search View', {
      query: query,
   });
};

export const homepageExploreSTAExpBtnEvent = () => {
   Mixpanel.track('Homepage - Explore STA Exp Btn');
};

export const homepageNewslettersSubscribeBtnEvent = (
   name: string,
   email: string,
   mobile: string
) => {
   Mixpanel.track('Homepage - Newsletters Subscribe Btn', {
      name: name,
      email: email,
      mobile: mobile,
   });
};

export const homepageNewslettersSubscribeBtnSuccessEvent = (
   name: string,
   email: string,
   mobile: string
) => {
   Mixpanel.track('Homepage - Newsletters Subscribe Btn Success', {
      name: name,
      email: email,
      mobile: mobile,
   });
};

export const homepageNewslettersSubscribeBtnSFailedEvent = (
   error: string,
   name: string,
   email: string,
   mobile: string
) => {
   Mixpanel.track('Homepage - Newsletters Subscribe Btn Failed', {
      error: error,
      name: name,
      email: email,
      mobile: mobile,
   });
};

export const homepageBuyVoucherBtnEvent = () => {
   Mixpanel.track('Homepage - Buy Voucher Btn');
};

export const homepageSellYourExpMidPageBtnEvent = () => {
   Mixpanel.track('Homepage - Sell Your Exp Mid Page Btn');
};

export const homepageSellYourExpFooterBtnEvent = () => {
   Mixpanel.track('Homepage - Sell Your Exp Footer Btn');
};

export const homepageKAFUCardEvent = ({ tournamentId, tournamentName, game }: any) => {
   Mixpanel.track('Homepage - KAFU Card', {
      tournamentId,
      tournamentName,
      game,
   });
};

export const homepageKAFUExpBtnEvent = () => {
   Mixpanel.track('Homepage - KAFU Explore All Btn');
};

export const homepageKAFULogoBtnEvent = () => {
   Mixpanel.track('Homepage - KAFU Logo Btn');
};

export const homepageTopLocatinBtnEvent = (city: string) => {
   Mixpanel.track('Homepage - Top Location Btn', {
      city,
   });
};

export const homepageCategoryBtnEvent = (category: string) => {
   Mixpanel.track('Homepage - Category Btn', {
      category,
   });
};

export const homepageUnforgettableExperiencesBtnEvent = (category: string) => {
   Mixpanel.track('Homepage - Unforgettable Experiences Btn', {
      category,
   });
};
