import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import classNames from 'classnames';
import styles from './styles.module.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import { memo } from 'react';

interface DatePickerProps extends ReactDatePickerProps {
   error?: React.ReactNode;
   valid?: React.ReactNode;
   id: string;
   label?: React.ReactNode;
   labelClassName?: string;
   groupClassName?: string;
   appendClassName?: string;
   append?: React.ReactElement | React.ReactNode;
   disabled?: boolean;
}

const Picker = ({
   className,
   label,
   id,
   labelClassName,
   groupClassName,
   appendClassName,
   append,
   error,
   valid,
   ...props
}: DatePickerProps) => {
   return (
      <div className={classNames(groupClassName || 'mb-2')}>
         {label && (
            <div>
               <label htmlFor={id} className={classNames(labelClassName, 'mb-1')}>
                  {label}
               </label>
            </div>
         )}
         <div className="relative flex">
            <DatePicker
               id={id}
               className={classNames(
                  styles['input'],
                  error ? styles['error'] : valid ? styles['valid'] : 'border border-gray-300',
                  className || 'bg-gray-200',
                  append && styles['appended']
               )}
               wrapperClassName="w-full"
               {...props}
            />
            {append && (
               <div
                  className={classNames(
                     'ltr:rounded-r-default rtl:rounded-l-default',
                     'border-gray-300 border h-auto flex items-center justify-center ltr:border-l-0 rtl:border-r-0 flex-shrink-0',
                     appendClassName || 'bg-gray-200 ltr:border-l rtl:border-r'
                  )}>
                  {append}
               </div>
            )}
         </div>
         {valid && !error && typeof valid !== 'boolean' && (
            <div className="mt-1 text-xs text-primary-success">{valid}</div>
         )}
         {error && typeof error !== 'boolean' && (
            <div className="mt-1 text-xs text-primary-error">{error}</div>
         )}
      </div>
   );
};
export default memo(Picker);
