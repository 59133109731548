import { useCallback, useEffect, useRef } from 'react';

type Props = {
   v2elementId?: string;
   autoRenderV2?: boolean;
};

const SPAMMY_CC = [973, 965];

/**
 * Used to manage both v2/v3 captcha
 * @returns
 */
const useRecaptcha = ({ v2elementId, autoRenderV2 }: Props = {}) => {
   const v2Token = useRef<string>();

   const renderV2Captcha = useCallback(async () => {
      if (v2elementId && typeof window !== 'undefined' && window?.grecaptcha) {
         window.grecaptcha.ready(() => {
            const el = document.getElementById(v2elementId);
            if (el && !el?.firstChild) {
               try {
                  const firstChild = document.createElement('div');
                  el.appendChild(firstChild);
                  window.grecaptcha.render(firstChild, {
                     sitekey: process.env.NEXT_PUBLIC_V2_CAPTCHA_KEY,
                     callback(response) {
                        v2Token.current = response;
                     },
                  });
               } catch (error) {
                  // TODO
                  console.log(error);
               }
            }
         });
      }
   }, [v2elementId]);

   const removeV2Captcha = () => {
      if (!v2elementId) return;

      const el = document.getElementById(v2elementId);
      if (el?.firstChild) {
         window.grecaptcha.reset();
         el.firstChild.remove();
         v2Token.current = '';
      }
   };

   useEffect(() => {
      // Load v2 script if needed
      if (autoRenderV2) {
         renderV2Captcha();
      }
   }, [autoRenderV2, v2elementId, renderV2Captcha]);

   /**
    * if is spammy, check if sloved v2Token
    * otherwise throw error
    */
   const executeRecaptcha = async (countryCode?: number) => {
      return new Promise((res, rej) => {
         try {
            if (countryCode && isSpammyCountry(countryCode) && !v2Token.current) {
               return rej({ message: 'Please complete captcha' });
            }
            window.grecaptcha.ready(() => {
               window.grecaptcha
                  .execute(process.env.NEXT_PUBLIC_CAPTCHA_KEY, { action: 'signup' })
                  .then(res);
            });
         } catch (error) {
            rej(error);
         }
      });
   };

   const isSpammyCountry = (countryCode: number) => {
      return SPAMMY_CC.includes(countryCode);
   };

   return {
      executeRecaptcha,
      renderV2Captcha,
      removeV2Captcha,
      isSpammyCountry,
   };
};

export default useRecaptcha;
