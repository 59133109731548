import { Mixpanel } from '../init';

export const signupPopUpViewEvent = () => {
   Mixpanel.track('Signup - Pop Up View');
};

export const signupNextBtnEvent = (mobile: string, country_code: string) => {
   Mixpanel.track('Signup - Next Btn', {
      mobile: mobile,
      country_code: country_code,
   });
};

export const signupNextBtnSuccessEvent = (mobile: string) => {
   Mixpanel.track('Signup - Next Btn Success', {
      mobile: mobile,
   });
};

export const signupNextBtnFailedEvent = (mobile: string, country_code: string, error: string) => {
   Mixpanel.track('Signup - Next Btn Failed', {
      mobile: mobile,
      country_code: country_code,
      error: error,
   });
};

export const signupResendOTPBtnEvent = (mobile: string) => {
   Mixpanel.track('Signup - Resend OTP Btn', {
      mobile: mobile,
   });
};

export const signupResendOTPBtnSuccessEvent = (mobile: string) => {
   Mixpanel.track('Signup - Resend OTP Btn Success', {
      mobile: mobile,
   });
};

export const signupResendOTPBtnFailedEvent = (mobile: string, error: string) => {
   Mixpanel.track('Signup - Resend OTP Btn Failed', {
      mobile: mobile,
      error: error,
   });
};

export const signupVerifyBtnEvent = (mobile: string) => {
   Mixpanel.track('Signup - Verify Btn', {
      mobile: mobile,
   });
};

export const signupVerifyBtnSuccessEvent = (mobile: string) => {
   Mixpanel.track('Signup - Verify Btn Success', {
      mobile: mobile,
   });
};

export const signupVerifyBtnFailedEvent = (mobile: string, error: string) => {
   Mixpanel.track('Signup - Verify Btn Failed', {
      mobile: mobile,
      error: error,
   });
};

export const personalInfoPageViewEvent = (mobile: string) => {
   Mixpanel.track('Personal Info - Page View', {
      mobile: mobile,
   });
};

export const personalInfoProceedToPaymentBtnEvent = (
   email: string,
   lastname: string,
   firstname: string
) => {
   Mixpanel.track('Personal Info - Proceed to Payment Btn', {
      email: email,
      lastname: lastname,
      firstname: firstname,
   });
};

export const personalInfoProceedToPaymentBtnSuccessEvent = (
   email: string,
   lastname: string,
   firstname: string
) => {
   Mixpanel.track('Personal Info - Proceed to Payment Btn Success', {
      email: email,
      lastname: lastname,
      firstname: firstname,
   });
};

export const personalInfoProceedToPaymentBtnFailedEvent = (
   error: any,
   email: string,
   lastname: string,
   firstname: string
) => {
   Mixpanel.track('Personal Info - Proceed to Payment Btn Failed', {
      error: error,
      email: email,
      lastname: lastname,
      firstname: firstname,
   });
};

export const mixpanelIdentify = (user: any) => {
   Mixpanel.identify(user._id);
   Mixpanel.people.set({
      $name: user.name,
      $email: user.email,
      $mobile: user.cell_number,
   });
};

export const mixpanelLogout = () => {
   Mixpanel.reset();
};

export const logoutLogoutBtnEvent = () => {
   Mixpanel.track('Logout - Logout Btn');
};

export const logoutConfirmBtnEvent = () => {
   Mixpanel.track('Logout - Logout Confirm Btn');
};
