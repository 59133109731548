import Modal, { ModalBody } from '~/components/common/modal';
import { useState } from 'react';
import MainBody from './main-body';
import HappyBody from './happy-body';
import MehBody from './meh-body';
import AngryBody from './angry-body';
import TextfieldBody from './textfield-body';
import SubmittedBody from './submitted-body';
import useMedia from '~/hooks/useMedia';
import BottomSheet from '../bottom-sheet';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslate } from '~/i18n';

type FeedbackModalProps = {
   open: boolean;
   handleClose: () => void;
};

const FeedbackContent = ({ open, handleClose }: FeedbackModalProps) => {
   const [feedback, setFeedback] = useState('main');
   const [textfieldEmote, setTextfieldEmote] = useState('');
   const [option, setOption] = useState('');
   const isMobile = useMedia(1100);
   const [height, setHeight] = useState(0);
   const { dir } = useTranslate();

   const updateHeight = (e: HTMLDivElement) => setHeight(e.offsetHeight);

   const interceptHandleClose = () => {
      handleClose();
      setFeedback('main');
   };

   const handleStateUpdate = (feedbackBody: string, option: string) => {
      // Get previous value before overwriting it
      if (feedbackBody === 'textfield') {
         setTextfieldEmote(feedback);
      }
      setFeedback(feedbackBody);

      setOption(option);
   };

   return (
      <div>
         {isMobile ? (
            <BottomSheet open={open} toggle={handleClose}>
               {feedback === 'main' && (
                  <MainBody
                     setBody={handleStateUpdate}
                     handleClose={handleClose}
                     updateHeight={updateHeight}
                  />
               )}
               {feedback === 'happy' && (
                  <HappyBody handleClose={interceptHandleClose} updateHeight={updateHeight} />
               )}
               {feedback === 'meh' && (
                  <MehBody
                     setBody={handleStateUpdate}
                     handleClose={interceptHandleClose}
                     updateHeight={updateHeight}
                  />
               )}
               {feedback === 'angry' && (
                  <AngryBody
                     setBody={handleStateUpdate}
                     handleClose={interceptHandleClose}
                     updateHeight={updateHeight}
                  />
               )}
               {feedback === 'textfield' && (
                  <TextfieldBody
                     setBody={handleStateUpdate}
                     handleClose={interceptHandleClose}
                     updateHeight={updateHeight}
                     title={option}
                     emote={textfieldEmote}
                  />
               )}
               {feedback === 'submit' && (
                  <SubmittedBody handleClose={interceptHandleClose} updateHeight={updateHeight} />
               )}
            </BottomSheet>
         ) : (
            <Modal open={open} size="xs" onClickOutside={handleClose}>
               <ModalBody
                  className="overflow-hidden transition-all duration-150 ease-in-out"
                  style={{ height: height + 'px' }}>
                  <AnimatePresence exitBeforeEnter={true}>
                     <motion.div
                        key={feedback}
                        variants={{
                           hidden: {
                              x:
                                 dir === 'rtl'
                                    ? feedback === 'main' || feedback == 'textfield'
                                       ? '110%'
                                       : '-110%'
                                    : feedback === 'main' || feedback == 'textfield'
                                    ? '-110%'
                                    : '110%',
                           },
                           show: { x: '0%' },
                        }}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        transition={{ duration: 0.25, easings: 'easeInOut' }}>
                        {feedback === 'main' && (
                           <MainBody
                              setBody={handleStateUpdate}
                              handleClose={handleClose}
                              updateHeight={updateHeight}
                           />
                        )}
                        {feedback === 'happy' && (
                           <HappyBody
                              handleClose={interceptHandleClose}
                              updateHeight={updateHeight}
                           />
                        )}
                        {feedback === 'meh' && (
                           <MehBody
                              setBody={handleStateUpdate}
                              handleClose={interceptHandleClose}
                              updateHeight={updateHeight}
                           />
                        )}
                        {feedback === 'angry' && (
                           <AngryBody
                              setBody={handleStateUpdate}
                              handleClose={interceptHandleClose}
                              updateHeight={updateHeight}
                           />
                        )}
                        {feedback === 'textfield' && (
                           <TextfieldBody
                              setBody={handleStateUpdate}
                              handleClose={interceptHandleClose}
                              updateHeight={updateHeight}
                              title={option}
                              emote={textfieldEmote}
                           />
                        )}
                        {feedback === 'submit' && (
                           <SubmittedBody
                              handleClose={interceptHandleClose}
                              updateHeight={updateHeight}
                           />
                        )}
                     </motion.div>
                  </AnimatePresence>
               </ModalBody>
            </Modal>
         )}
      </div>
   );
};

export default FeedbackContent;
