import Image from 'next/image';
import { useRouter } from 'next/router';
import { Translate, useTranslate } from '~/i18n';
import Container from '~/components/common/container';
import { CloseIcon } from '~/svgs/common';

const WebookHeader = ({
   setShowWebookHeader,
}: {
   setShowWebookHeader: (state: boolean) => void;
}) => {
   const { lang, locale } = useTranslate();
   const router = useRouter();
   const isTicketingPage = router.pathname === `/[lang]/ticketing`;
   const isHomePage = router.pathname === `/[lang]`;
   const Tag = isHomePage ? 'a' : 'div';

   return (
      <div className="hidden tablet:block relative mb-24">
         <div
            className="fixed top-0 z-50 !bg-black w-full"
            style={{
               background:
                  'linear-gradient(135deg, #1E3D56 17.1%, rgba(31, 62, 88, 0.00) 51.46%), #000',
            }}>
            <button
               aria-label="close"
               onClick={() => setShowWebookHeader(false)}
               className="absolute ltr:right-2 rtl:left-2 top-2 z-60">
               <CloseIcon className="text-white" />
            </button>
            <Tag href={`https://webook.com/${locale}`} target="_blank" rel="noreferrer">
               <Container className="h-28 flex items-center justify-between !text-white">
                  <h1
                     className="font-semibold text-lg lg:text-[24px]"
                     style={{ maxWidth: isHomePage ? '300px' : '' }}>
                     <Translate id="home:webook rebrand title" />
                  </h1>
                  {isHomePage ? (
                     <Image
                        priority
                        src="/images/weebook/hy-to-weebook.svg"
                        width={385}
                        height={105}
                     />
                  ) : null}
                  <div className="flex gap-4 items-center">
                     <p className="text-sm" style={{ maxWidth: isHomePage ? '320px' : '390px' }}>
                        {!isHomePage ? (
                           <Translate id="home:webook business page description" />
                        ) : (
                           <Translate id="home:webook rebrand subtitle" />
                        )}
                     </p>
                     {!isHomePage ? (
                        <a
                           href={
                              isTicketingPage
                                 ? `/${lang}/ticketing#request-callback-wrapper`
                                 : `https://pro.halayalla.com/${locale}/signup`
                           }
                           target={isTicketingPage ? '_self' : '_blank'}
                           rel="noreferrer">
                           <button className="px-2 -mt-1 py-1 bg-[#0552bc] rounded-md text-sm">
                              {isTicketingPage ? (
                                 <Translate id="business:request a call back" />
                              ) : (
                                 <Translate id="business:signup now" />
                              )}
                           </button>
                        </a>
                     ) : null}
                  </div>
               </Container>
            </Tag>
         </div>
      </div>
   );
};
export default WebookHeader;
