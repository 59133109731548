import classnames from 'classnames';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import useMedia from '~/hooks/useMedia';
import { NextLink, useTranslate } from '~/i18n';
import { ArrowUpLeft, ArrowUpRight } from '~/svgs/navbar';
import styles from './styles/styles.module.css';

interface ILink {
   href: string;
   trackEvent?: () => void;
   text: React.ReactNode;
   icon?: React.ReactNode;
   external?: boolean;
   mobile?: boolean;
   exact?: boolean;
   textClassName?: string;
   className?: string;
   linkClassName?: string;
}

const NavLink: React.FC<ILink> = ({
   icon,
   href,
   text,
   external,
   mobile,
   exact,
   textClassName,
   className,
   linkClassName,
   trackEvent,
}) => {
   const { lang, dir } = useTranslate();
   const router = useRouter();
   const isTablet = useMedia(1100);

   if (external) {
      return (
         <li
            key={href}
            className={classnames(styles['nav-link'], 'h-full border-none', className, {
               'flex-grow': mobile,
            })}
            onClick={() => trackEvent && trackEvent()}>
            <a
               href={href}
               className={classnames(
                  'justify-center h-full block py-1 xl:py-2 border-none',
                  linkClassName ? '' : 'hover:bg-gray-100'
               )}
               target="_blank"
               rel="noreferrer noopener">
               <Fragment>
                  <div
                     className={classnames(
                        linkClassName,
                        'py-2 px-1 flex items-center border-none'
                     )}>
                     {icon}
                     <span className={classnames(textClassName)}>{text}</span>
                     {external && <div>{dir === 'ltr' ? <ArrowUpRight /> : <ArrowUpLeft />}</div>}
                  </div>
               </Fragment>
            </a>
         </li>
      );
   }

   const currentPath = router.asPath;
   const isCurrentPath = exact
      ? currentPath === `/${lang}${href}`
      : currentPath.startsWith(`/${lang}${href}`);

   const getSelector = () => {
      if (isTablet && isCurrentPath) {
         return null;
      } else if (isCurrentPath) {
         return (
            <motion.div
               layoutId="underline"
               className="absolute top-0 bottom-0 right-0 w-full bg-white bg-opacity-30 tablet:rounded-full"
               transition={{ duration: 0.175, ease: 'easeOut' }}
            />
         );
      }
   };

   return (
      <li
         key={href}
         className={classnames(styles['nav-link'], 'h-full', className, {
            'flex-grow': mobile,
            'font-semibold border-b-2 border-primary-color': isCurrentPath && !mobile,
         })}
         onClick={() => trackEvent && trackEvent()}>
         <NextLink
            exact
            href={href}
            className={classnames(
               'justify-center h-full block py-1 xl:py-2',
               linkClassName ? '' : 'hover:bg-gray-100'
            )}
            activeClassName={styles['active']}>
            <Fragment>
               <div
                  className={classnames(
                     linkClassName,
                     {
                        'flex-col': mobile,
                        'justify-center': mobile,
                        'active-icon': isCurrentPath,
                     },
                     'flex items-center relative z-10 py-2 px-1'
                  )}>
                  {icon}
                  <span
                     className={classnames('block tablet:inline mt-1 tablet:mt-0', textClassName)}>
                     {text}
                  </span>
               </div>
            </Fragment>
         </NextLink>

         {getSelector()}
      </li>
   );
};

export default NavLink;
