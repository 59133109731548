import { FC, useEffect, useRef } from 'react';
import { useAnimation } from 'framer-motion';
import usePrevious from '~/hooks/use-previous';
import { ArrowDownIcon } from '~/svgs/common';
import cn from 'classnames';
import { BottomSheet as BS, BottomSheetProps } from 'react-spring-bottom-sheet';
interface BSProps extends BottomSheetProps {
   // onClose?: () => any;
   // eslint-disable-next-line no-unused-vars
   toggle?: (current?: boolean) => any;
   open: boolean;
   showCloseButton?: boolean;
   unscrollable?: boolean;
   footer?: React.ReactNode;
   header?: React.ReactNode;
}

const BottomSheet: FC<BSProps> = ({
   children,
   open,
   toggle,
   showCloseButton,
   unscrollable,
   footer,
   header,
   ...props
}) => {
   const prevIsOpen = usePrevious(open);
   const controls = useAnimation();

   const didOpen = useRef(false);

   useEffect(() => {
      if (prevIsOpen && !open) {
         controls.start('hidden');
      } else if (!prevIsOpen && open) {
         controls.start('visible');
      }
   }, [controls, open, prevIsOpen]);

   useEffect(() => {
      if (open && !didOpen.current) {
         history.pushState('show', 'show sheet');
         didOpen.current = true;
      } else if (!open && didOpen.current) {
         // console.log('bye')
         //TODO: Fix history back on close
         // history.back();
         didOpen.current = false;
      }
      // return () => {
      //    if (open && didOpen.current) history.back();
      // };
      // else history.back();
   }, [open]);

   useEffect(() => {
      window.onpopstate = () => {
         didOpen.current = false;
         toggle?.(false);
      };
   }, [toggle]);

   if (typeof window === 'undefined') {
      return null;
   }

   return (
      <BS
         open={open}
         onDismiss={toggle}
         className="bottom-sheet"
         footer={footer}
         header={header}
         expandOnContentDrag
         {...props}>
         <div>
            {showCloseButton ? (
               <div className="flex justify-end px-4">
                  <button
                     className="focus:ring focus:ring-gray-light focus:outline-none"
                     onClick={() => toggle?.(false)}>
                     <ArrowDownIcon className="w-4" />
                  </button>
               </div>
            ) : null}
            <div
               className={cn(
                  'h-full pb-4 rounded-t-cornered',
                  !unscrollable && ' overflow-y-auto'
               )}>
               {children}
            </div>
         </div>
      </BS>
   );
};

export default BottomSheet;
