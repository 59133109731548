import axios from 'axios';
import Cookies from 'js-cookie';
import jsCookie from 'js-cookie';
/** -------------------------------------------------------------------------- **/
/**        This file should have all axios instances, add more if needed       **/
/** -------------------------------------------------------------------------- **/

/** --------- Make sure to add their keys inside enviroment variables -------- **/

const baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;
const token = process.env.NEXT_PUBLIC_API_TOKEN;
// Support API
const supportURL = process.env.NEXT_PUBLIC_SUPPORT_BASE;
export const supportAxios = axios.create({
   baseURL: supportURL,
   headers: { token },
});
const Axios = axios.create({
   baseURL,
   headers: { token },
});

/** --------------------------- Interceptor example -------------------------- **/

/** -------------------------------------------------------------------------- **/
/**           Read axios docs: {@link https://github.com/axios/axios}          **/
/** -------------------------------------------------------------------------- **/

Axios.interceptors.request.use(function (config) {
   if (typeof window !== 'undefined') {
      const guest = Cookies.get('guest');
      const sessionUser = guest ? JSON.parse(guest) : null;
      const token = jsCookie.get('token') || sessionUser?.access_token;
      if (token) {
         config.headers.Authorization = 'Bearer ' + token;
      }
   }

   return config;
});

Axios.interceptors.response.use(
   function (response) {
      return Promise.resolve(response);
   },
   function (error) {
      if (error.message === 'Network Error') {
         error.message =
            "You've exceeded the maximum number of attempts for this action. Please try again in a while.";
      }
      return Promise.reject(error);
   }
);
export const VSAxios = axios.create({
   baseURL: baseURL + '/vs',
   headers: { token },
});

VSAxios.interceptors.request.use(function (config) {
   if (typeof window !== 'undefined') {
      const guest = Cookies.get('guest');
      const sessionUser = guest ? JSON.parse(guest) : null;
      const token = jsCookie.get('token') || sessionUser?.access_token;
      if (token) {
         config.headers.Authorization = 'Bearer ' + token;
      }
   }

   return config;
});

VSAxios.interceptors.response.use(
   function (response) {
      return Promise.resolve(response);
   },
   function (error) {
      if (error.message === 'Network Error') {
         error.message =
            "You've exceeded the maximum number of attempts for this action. Please try again in a while.";
      }
      return Promise.reject(error);
   }
);

export default Axios;

// Tickets API
const ticketsURL = process.env.NEXT_PUBLIC_TICKETS_API_BASE;
const ticketsToken = process.env.NEXT_PUBLIC_TICKETS_API_TOKEN;
export const TicketsAxios = axios.create({
   baseURL: ticketsURL,
   headers: { token: ticketsToken },
});
