import { memo } from 'react';
import Select, { Props, Theme } from 'react-select';
import SelectStyles from '~/css/react-select';
import { useTranslate } from '~/i18n';
import classNames from 'classnames';

interface UISelectProps extends Props {
   innerRef?: any;
   error?: React.ReactNode;
   valid?: React.ReactNode;
   label?: string | React.ReactNode;
   labelClassName?: string;
   groupClassName?: string;
}

const UISelect: React.FC<UISelectProps> = ({
   id,
   innerRef,
   valid,
   error,
   styles,
   label,
   labelClassName,
   groupClassName,
   ...props
}) => {
   const { translate } = useTranslate();

   const selectTheme = ({ borderRadius, ...theme }: Theme) => ({
      borderRadius: borderRadius || SelectStyles.borderRadius,
      ...theme,
      spacing: {
         ...theme.spacing,
      },
      colors: {
         ...theme.colors,
         text: 'green',
         primary: error
            ? SelectStyles.errorColor
            : valid
            ? SelectStyles.successColor
            : SelectStyles.borderColors,
         primary75: SelectStyles.optionFocusedBackgroundColor,
         primary50: SelectStyles.optionFocusedBackgroundColor,
         primary25: SelectStyles.optionBackgroundColor,
         neutral0: SelectStyles.backgroundColor,
         neutral5: '#fff',
         neutral10: 'transparent',
         neutral20: error
            ? SelectStyles.errorColor
            : valid
            ? SelectStyles.successColor
            : SelectStyles.defaultBorderColors,
         neutral30: error
            ? SelectStyles.errorColor
            : valid
            ? SelectStyles.successColor
            : SelectStyles.borderHoverColor,
         neutral40: SelectStyles.noOptionsColor,
         neutral50: SelectStyles.placeholderColor,
         neutral60: SelectStyles.focusedArrowColor,
         neutral70: '#fff',
         neutral80: SelectStyles.searchColor,
         neutral90: '#fff',
      },
   });

   const selectStyles: any = {
      ...styles,
      option: (style: any, state: any) => ({
         ...style,
         color: SelectStyles.searchColor,
         backgroundColor: state.isFocused
            ? SelectStyles.optionFocusedBackgroundColor
            : style.backgroundColor,
         '&:hover': {
            backgroundColor: SelectStyles.optionFocusedBackgroundColor,
         },
         '&:active': {
            backgroundColor: SelectStyles.optionFocusedBackgroundColor,
         },
      }),
      input: () => ({
         outline: 'none',
         border: 'none',
      }),
      dropdownIndicator: (style: any) => ({
         ...style,
         color: SelectStyles.searchColor,
      }),
   };

   return (
      <div className={groupClassName || ''}>
         {label && (
            <label htmlFor={id}>
               <span
                  className={classNames(
                     'mb-2 sm:text-base text-sm',
                     labelClassName,
                     error ? 'text-primary-error' : valid ? 'text-primary-success' : ''
                  )}>
                  {label}
               </span>
            </label>
         )}
         <Select
            className="react-select"
            inputId={id}
            instanceId={id}
            styles={selectStyles}
            noOptionsMessage={message =>
               `${translate({ id: 'common:no results' })} ${message.inputValue}`
            }
            ref={innerRef}
            theme={selectTheme}
            placeholder={translate({ id: 'common:select_placholder' })}
            {...props}
         />
         {valid && !error && typeof valid !== 'boolean' && (
            <div className="mt-1 text-xs text-primary-success">{valid}</div>
         )}
         {error && typeof error !== 'boolean' && (
            <div className="mt-1 text-xs text-primary-error">{error}</div>
         )}
      </div>
   );
};

UISelect.displayName = 'select';

export default memo(UISelect);
