import { useEffect, useState } from 'react';
interface TimerProps {
   time?: number;
   onTimeFinished?: () => any;
   // eslint-disable-next-line no-unused-vars
   component: (props: any) => any;
}
const Timer = ({ time = 60, onTimeFinished, component: Component }: TimerProps) => {
   const [t, setTime] = useState(time);

   useEffect(() => {
      const timeout = setTimeout(() => {
         setTime(t - 1);
      }, 1000);

      if (t <= 0) {
         clearTimeout(timeout);
         onTimeFinished && onTimeFinished();
      }

      return () => clearTimeout(timeout);
   }, [t, onTimeFinished]);

   return <Component time={t} />;
};

export default Timer;
