import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import * as pixel from '~/utils/pixel-tracking';

const handleRouteChange = () => {
   pixel.pageview();
};

const FacebookPixel: FC<any> = ({ children, preventPageViews }) => {
   const router = useRouter();

   useEffect(() => {
      // This pageview only trigger first time (it is important for Pixel to have real information)

      if (!preventPageViews) {
         pixel.pageview();
         router.events.on('routeChangeComplete', handleRouteChange);
         return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
         };
      }
   }, [router, preventPageViews]);

   return children;
};

export default FacebookPixel;
