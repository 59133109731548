import { Translate } from '~/i18n';

const links = [
   {
      title: <Translate id="jarir:customer care" />,
      children: [
         {
            title: <Translate id="jarir:corporate sales" />,
            href: 'corporatesales',
            external: true,
         },
         {
            title: <Translate id="jarir:faq" />,
            href: '/faq',
            external: false,
         },
         {
            title: <Translate id="jarir:shopping guide" />,
            href: 'shoppingguide/#catalogues',
            external: true,
         },
         {
            title: <Translate id="jarir:showroom locator" />,
            href: 'storelocator',
            external: true,
         },
         {
            title: <Translate id="jarir:warranty policy" />,
            href: 'warranty_policy',
            external: true,
         },
         // {
         //    title: <Translate id="jarir:return and exchange" />,
         //    href: 'return_exchange',
         //    external: true,
         // },
         {
            title: <Translate id="jarir:contact us" />,
            href: 'contacts',
            external: true,
         },
      ],
   },
   {
      title: <Translate id="jarir:jarir services" />,
      children: [
         {
            title: <Translate id="jarir:installment service" />,
            href: 'installment-service',
            external: true,
         },
         {
            title: <Translate id="jarir:after sale services" />,
            href: 'afs',
            external: true,
         },
         {
            title: <Translate id="jarir:total protection service" />,
            href: 'total-protection-service',
            external: true,
         },
         {
            title: <Translate id="jarir:discount card" />,
            href: 'jarir-discount-card',
            external: true,
         },
         {
            title: <Translate id="jarir:gift card" />,
            href: 'jarir-gift-certificate',
            external: true,
         },
      ],
   },
   {
      title: <Translate id="jarir:quick links" />,
      children: [
         {
            title: <Translate id="jarir:jarir publications" />,
            href: 'jarirpublications',
            external: true,
         },
         {
            title: <Translate id="jarir:reward program partners" />,
            href: 'rewardprogramspartner',
            external: true,
         },
         {
            title: <Translate id="jarir:telecom services" />,
            href: 'telecom-services',
            external: true,
         },
      ],
   },
   {
      title: <Translate id="jarir:about jarir" />,
      children: [
         {
            title: <Translate id="jarir:who we are" />,
            href: 'jarir-company-profile',
            external: true,
         },
         {
            title: <Translate id="jarirterms:terms and conditions" />,
            href: '/terms-conditions',
            external: false,
         },
         {
            title: <Translate id="jarir:corporate governance" />,
            href: 'corporate-governance',
            external: true,
         },
         {
            title: <Translate id="jarir:investors relations" />,
            href: 'financialreports',
            external: true,
         },
         {
            title: <Translate id="jarir:sustainability" />,
            href: 'sustainability',
            external: true,
         },
         {
            className: 'font-bold',
            title: <Translate id="jarir:job opportunities" />,
            href: 'jobs',
            external: true,
         },
      ],
   },
];

export default links;
