import styles from './styles.module.css';
import classNames from 'classnames';
import { memo } from 'react';

type RadioCheckProps = {
   label: React.ReactNode;
   type: 'checkbox' | 'radio';
   inputSize?: '2' | '4' | '5' | '6' | '8' | '10' | '12' | '16' | '20';
   inputClassName?: string;
   append?: never;
   prepend?: never;
   appendClassName?: never;
   prependClassName?: never;
   maxLength?: never;
   max?: never;
   checked?: boolean;
};

type InputDefaults = {
   label?: React.ReactNode;
   type?: 'text' | 'email' | 'password' | 'number' | 'textarea' | 'date';
   inputSize?: never;
   inputClassName?: never;
   append?: React.ReactElement | React.ReactNode;
   prepend?: React.ReactElement | React.ReactNode;
   appendClassName?: string;
   prependClassName?: string;
   maxLength?: number;
   max?: number | string;
   readOnly?: boolean;
};

export type InputProps = {
   labelClassName?: string;
   error?: React.ReactNode;
   valid?: React.ReactNode;
   groupClassName?: string;
   name?: string;
   innerRef?: any;
   disabled?: boolean;
   autoComplete?: string;
   inputWrapperClassName?: string;
   rows?: number;
   value?: string;
} & (RadioCheckProps | InputDefaults) &
   React.HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>;

const Input = ({
   label,
   error,
   valid,
   className,
   labelClassName,
   groupClassName,
   appendClassName,
   prependClassName,
   id = '',
   type = 'text',
   inputSize = '5',
   inputClassName,
   innerRef,
   append,
   prepend,
   maxLength,
   max,
   autoComplete,
   inputWrapperClassName,
   ...props
}: InputProps) => {
   if (type === 'radio' || type === 'checkbox') {
      return (
         <label
            htmlFor={id}
            className={classNames('text-primary-color inline-flex cursor-pointer', className)}>
            <input
               autoComplete={autoComplete}
               id={id}
               type={type}
               ref={innerRef}
               className={classNames(
                  `rounded-[4px] border-2 text-primary-color border-gray-300 focus:ring-primary-color w-${inputSize} h-${inputSize}`,
                  inputClassName
               )}
               {...props}
            />
            <span
               className={classNames(
                  'ltr:ml-2 rtl:mr-2 sm:text-base text-xs',
                  labelClassName,
                  error ? 'text-primary-error' : valid ? 'text-primary-success' : ''
               )}>
               {label}
            </span>
         </label>
      );
   }

   const Tag = type === 'textarea' ? 'textarea' : 'input';
   return (
      <div className={classNames(groupClassName || 'mb-2')}>
         {label && (
            <div>
               <label
                  htmlFor={id}
                  className={classNames(labelClassName, 'text-sm mb-1 inline-block')}>
                  {label}
               </label>
            </div>
         )}
         <div className={classNames('flex', inputWrapperClassName)}>
            {prepend && (
               <div
                  className={classNames(
                     'rtl:rounded-r-default ltr:rounded-l-default rtl:border-l ltr:border-r',
                     'border-gray-300 border h-auto flex items-center justify-center rtl:border-l-0 ltr:border-r-0 flex-shrink-0',
                     prependClassName || 'bg-gray-100'
                  )}>
                  {prepend}
               </div>
            )}
            <Tag
               autoComplete={autoComplete}
               id={id}
               ref={innerRef}
               type={type}
               maxLength={maxLength}
               max={max}
               className={classNames(
                  styles['input'],
                  error ? styles['error'] : valid ? styles['valid'] : 'border-gray-300',
                  className || 'bg-gray-100',
                  append && styles['appended'],
                  prepend && styles['prepended']
               )}
               {...props}
            />
            {append && (
               <div
                  className={classNames(
                     'ltr:rounded-r-default rtl:rounded-l-default',
                     'border-gray-300 border h-auto flex items-center justify-center ltr:border-l-0 rtl:border-r-0 flex-shrink-0',
                     appendClassName || 'bg-gray-100 ltr:border-l rtl:border-r'
                  )}>
                  {append}
               </div>
            )}
         </div>

         {valid && !error && typeof valid !== 'boolean' && (
            <div className="mt-1 text-xs text-primary-success">{valid}</div>
         )}
         {error && typeof error !== 'boolean' && (
            <div className="mt-1 text-xs text-primary-error">{error}</div>
         )}
      </div>
   );
};
Input.displayName = `Input`;
export default memo(Input);
