import React, { ErrorInfo } from 'react';
import { sentryCaptureException, sentryLastEventId, sentryShowReportDialog } from '~/errors/sentry';
import Layout from '../layout';
import { Translate } from '~/i18n';
import Button from '../common/button';
import WithTranslation from '~/i18n/with-translation';
import { TranslateProps } from '~/i18n/context';
import { NextSeo } from 'next-seo';
import Link from 'next/link';

type Props = {
   translate?: (options: TranslateProps) => string;
   locale?: string;
   children: React.ReactNode | React.ReactNode[];
};

type State = {
   hasError: boolean;
   eventId?: string;
};

class ErrorBoundary extends React.Component<Props, State> {
   constructor(props: Props) {
      super(props);
      this.state = { hasError: false };
   }

   static getDerivedStateFromError() {
      return { hasError: true, eventId: sentryLastEventId() };
   }

   showReportDialog = () => {
      sentryShowReportDialog({
         eventId: this.state.eventId,
         lang: this.props.locale,
         title: this.props.translate?.({ id: 'common:boundry_error' }),
         subtitle: this.props.translate?.({ id: 'common:boundry_error_description' }),
         subtitle2: '',
         labelClose: this.props.translate?.({ id: 'common:cancel' }),
         labelSubmit: this.props.translate?.({ id: 'common:send_report' }),
         labelComments: this.props.translate?.({ id: 'common:boundry_error_label_comments' }),
         successMessage: this.props.translate?.({ id: 'common:send_report_success' }),
      });
   };

   componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      this.showReportDialog();
      error.name = '[Fatal] ErrorBoundary';
      error.message = error.message || 'No message attached';
      sentryCaptureException(error, {
         extra: { errorInfo },
         level: 'fatal',
         tags: { ErrorBoundary: true },
      });
   }

   render() {
      if (this.state.hasError) {
         return (
            <>
               <NextSeo title={this.props.translate?.({ id: 'common:boundry_error' })} />
               <div
                  className="fixed w-full h-full bg-center bg-cover"
                  style={{ backgroundImage: 'url("/images/sand.jpg")', zIndex: -1 }}></div>
               <Layout smallFooter>
                  <section className="container max-w-xl mx-auto my-28">
                     <div className="px-6 py-10 mx-4 bg-white/40 shadow-md">
                        <h1 className="mb-6 text-3xl text-center capitalize">
                           <strong>
                              <Translate id="common:boundry_error" />
                           </strong>
                        </h1>
                        <p className="py-4 text-gray-500">
                           <Translate id="common:boundry_error_description" />
                        </p>
                        <div className="max-w-xs mx-auto">
                           {this.state.eventId && (
                              <Button
                                 className="w-full mt-6 text-lg"
                                 onClick={this.showReportDialog}>
                                 <Translate id="common:send_report" />
                              </Button>
                           )}
                           <Link href="/" className="w-full mt-6 text-lg" tabIndex={-1}>
                              <a>
                                 <Button className="w-full mt-6 text-lg" type="secondary">
                                    <Translate id="common:home" />
                                 </Button>
                              </a>
                           </Link>
                        </div>
                     </div>
                  </section>
               </Layout>
               <style>{`
                  #__next {
                     display: flex;
                     flex-direction: column;
                     min-height: 100vh;
                  }
                  #__next main {
                     flex: 1;
                     overflow-x: hidden; 
                  }
               `}</style>
            </>
         );
      }

      return this.props.children;
   }
}

export default WithTranslation()(ErrorBoundary);
