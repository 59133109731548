import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import { isJarir } from './theme';

/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                This file should have all analaytics configs                */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

/* ------ Make sure to add their keys inside enviroment variables ------ */

export const initGTM = () => {
   if (process.env.NEXT_PUBLIC_ENV === 'production') {
      TagManager.initialize({
         gtmId: isJarir
            ? process.env.NEXT_PUBLIC_JARIR_GOOGLE_TAG
            : process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER,
      });
   }
};

export const initHOTJAR = () => {
   if (process.env.NEXT_PUBLIC_ENV === 'production') {
      const hjid = isJarir ? 2818916 : 1767605;
      const hjsv = 6;

      hotjar.initialize(hjid, hjsv);
   }
};
