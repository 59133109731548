import Image from 'next/image';
import { BrandLogo } from '~/components/layout/header/brand';
import { Translate } from '~/i18n';
import { isJarir } from '~/utils/theme';

type CustomLayoutFooterProps = {
   customLogo?: React.ReactNode;
};
const CustomLayoutFooter = ({ customLogo }: CustomLayoutFooterProps) => {
   return (
      <div className="flex flex-col items-center justify-between px-2 py-3 space-y-6 text-gray-600 bg-white footer md:px-12 tablet:px-24 sm:flex-row sm:space-y-0">
         <div className="items-center hidden space-x-4 lg:flex rtl:space-x-reverse tablet:w-1/3">
            <div>
               <Image width={46} height={16} src="/images/common/visa.svg" />
            </div>
            <div>
               <Image width={31} height={24} src="/images/common/mastercard.svg" />
            </div>
            <div>
               <Image width={27} height={27} src="/images/common/american-express.svg" />
            </div>
            <div>
               <Image width={46} height={16} src="/images/common/mada.svg" />
            </div>
            <div>
               <Image width={58} height={18} src="/images/common/paytabs.svg" />
            </div>
            <div>
               <Image width={70} height={25} src="/images/common/ssl-secure-grey.png" />
            </div>
         </div>
         <div className="opacity-75">
            {customLogo ? customLogo : <BrandLogo scrolled isFooter />}
         </div>
         {!isJarir && !customLogo && (
            <div className="flex justify-end space-x-4 rtl:space-x-reverse tablet:w-1/3">
               {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
               <a href="/about">
                  <Translate id="common:about" />
               </a>
               <a href={`https://api.whatsapp.com/send/?phone=966535050833&text&app_absent=0`}>
                  <Translate id="common:contact" />
               </a>
               <a href="">
                  <Translate id="common:FAQs" />
               </a>
            </div>
         )}
      </div>
   );
};

export default CustomLayoutFooter;
