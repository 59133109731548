import { forwardRef } from 'react';
import classNames from 'classnames';
export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
   type?: 'primary' | 'secondary';
   btnType?: 'submit' | 'button';
   disabled?: boolean;
   innerRef?: any;
}
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
   (
      { children, btnType = 'button', className, type = 'primary', disabled, innerRef, ...props },
      ref
   ) => {
      return (
         <button
            ref={ref || innerRef}
            type={btnType}
            className={classNames(
               'border border-primary-color shadow rounded-default px-4 py-3',
               type === 'primary'
                  ? 'bg-primary-color text-white hover:opacity-75'
                  : 'bg-white text-primary-color hover:bg-gray-100 focus:bg-gray-100',
               'transition duration-150 ease-in-out  focus:outline-none focus:ring-1 ring-offset-1 focus:ring-primary-color',
               disabled &&
                  type === 'primary' &&
                  'bg-opacity-90 border-opacity-75 hover:opacity-100 cursor-not-allowed',
               disabled &&
                  type === 'secondary' &&
                  'opacity-90 hover:opacity-100 cursor-not-allowed',
               className
            )}
            disabled={disabled}
            {...props}>
            {children}
         </button>
      );
   }
);
Button.displayName = 'Button';

export default Button;
