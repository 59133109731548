import { FC, memo, useState } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Img, { ImageProps } from 'next/image';
// import ImageProps from '~/interfaces/image';
import { publicUrl } from '../../../utils/publicUrl';
import { useInView } from 'react-intersection-observer';
import { ImageWithPlaceholder } from '~/utils/image-plaiceholder';
import { isJarir } from '~/utils/theme';

const ImageLoader = dynamic(() => import('../loaders/image'), { ssr: false });

type ImgComponentProps = ImageProps & {
   loaderBorderRadius?: string | number;
   loaderWrapperClassName?: string;
   imageWrapperClassName?: string;
   optimized_image?: ImageWithPlaceholder;
};

const Image: FC<ImgComponentProps> = ({
   loaderBorderRadius,
   loaderWrapperClassName,
   imageWrapperClassName,
   optimized_image,
   onLoad,
   onError,
   src,
   ...props
}) => {
   const hide_loader = true;
   const [loading, setLoading] = useState(optimized_image?.css ? false : true);
   const { ref, inView } = useInView({ threshold: 0.1, rootMargin: '50px', triggerOnce: true });
   return (
      <div ref={ref} className={classNames(imageWrapperClassName || 'relative h-full')}>
         {optimized_image?.css ? (
            <div
               style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  transform: 'scale(1.5)',
                  filter: 'blur(40px)',
                  ...optimized_image.css,
               }}
            />
         ) : null}
         <Img
            src={src || (isJarir ? '/jarir/notfound.svg' : '/images/not_found.svg')}
            {...props}
            onLoad={e => {
               setLoading(false);
               onLoad && onLoad(e);
            }}
            onError={e => {
               setLoading(false);
               const image = e.target as HTMLImageElement;
               image.srcset = '';
               image.src = isJarir
                  ? publicUrl('jarir/not_found.svg')
                  : publicUrl('images/not_found.svg');
               onError && onError(e);
            }}
         />

         {!hide_loader && loading && !optimized_image && (
            <div
               className={classNames(
                  loaderWrapperClassName,
                  'absolute inset-0 flex justify-center items-center bg-white'
               )}>
               {inView && <ImageLoader borderRadius={loaderBorderRadius} />}
            </div>
         )}
      </div>
   );
};
Image.displayName = 'Image';
export default memo(Image);
