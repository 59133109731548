import { CouponLottieAnimation } from '~/components/lottie/coupon';
import { ExperienceIcon } from '~/svgs/navbar';
import FeedbackButton from '~/components/common/feedback/feedback-button';
import { Translate } from '~/i18n';
import { navbarExpTabEvent } from '~/utils/mixpanel-events/navbar';

export const links = [
   {
      href: '/all-cities/experiences',
      trackEvent: navbarExpTabEvent,
      InnerIcon: ExperienceIcon,
      HomeIcon: ExperienceIcon,
      text: <Translate id="common:experiences" />,
      item: {
         isPage: true,
         external: false,
         exact: false,
      },
      primaryLinkDefault: true,
      primaryLinkExpanded: false,
      tabletPrimaryLinkDefault: true,
      tabletPrimaryLinkExpanded: false,
      fullScreenDropdown: {
         whenExpanded: false,
         whenDefault: false,
      },
      tabletDropdown: {
         whenExpanded: false,
         whenDefault: false,
      },
      dropDownIcon: '',
   },
   // {
   //    href: 'https://blog.halayalla.com',
   //    trackEvent: navbarHYBlogTabEvent,
   //    InnerIcon: BlogIcon,
   //    HomeIcon: BlogIcon,
   //    text: <Translate id="common:blog" />,
   //    item: {
   //       isPage: true,
   //       external: true,
   //    },
   //    primaryLinkDefault: true,
   //    primaryLinkExpanded: false,
   //    tabletPrimaryLinkDefault: true,
   //    tabletPrimaryLinkExpanded: false,
   //    fullScreenDropdown: {
   //       whenExpanded: false,
   //       whenDefault: false,
   //    },
   //    tabletDropdown: {
   //       whenExpanded: false,
   //       whenDefault: false,
   //    },
   //    dropDownIcon: '',
   // },
   {
      href: '/ticketing',
      InnerIcon: CouponLottieAnimation,
      HomeIcon: CouponLottieAnimation,
      text: <Translate id="common:sell your event" />,
      item: {
         isPage: true,
         external: false,
         exact: false,
      },
      primaryLinkDefault: true,
      primaryLinkExpanded: false,
      tabletPrimaryLinkDefault: true,
      tabletPrimaryLinkExpanded: false,
      fullScreenDropdown: {
         whenExpanded: false,
         whenDefault: false,
      },
      tabletDropdown: {
         whenExpanded: false,
         whenDefault: false,
      },
      dropDownIcon: '',
   },
   // {
   //    href: 'https://www.halayalla.com/about.php',
   //    trackEvent: navbarAboutUsTabEvent,
   //    querylang: true,
   //    InnerIcon: null,
   //    HomeIcon: null,
   //    text: <Translate id="common:about us" />,
   //    item: {
   //       isPage: true,
   //       external: true,
   //    },
   //    primaryLinkDefault: false,
   //    primaryLinkExpanded: false,
   //    tabletPrimaryLinkDefault: false,
   //    tabletPrimaryLinkExpanded: false,
   //    fullScreenDropdown: {
   //       whenExpanded: false,
   //       whenDefault: false,
   //    },
   //    tabletDropdown: {
   //       whenExpanded: false,
   //       whenDefault: true,
   //    },
   //    dropDownIcon: '/images/common/hy-icon-white.svg',
   // },
   // {
   //    href: '/business',
   //    trackEvent: navbarHYBusinessTabEvent,
   //    InnerIcon: RockOn,
   //    HomeIcon: RockOn,
   //    text: <Translate id="common:hala yalla for business" />,
   //    item: {
   //       isPage: true,
   //       external: false,
   //       exact: true,
   //    },
   //    primaryLinkDefault: false,
   //    primaryLinkExpanded: false,
   //    tabletPrimaryLinkDefault: false,
   //    tabletPrimaryLinkExpanded: false,
   //    fullScreenDropdown: {
   //       whenExpanded: false,
   //       whenDefault: false,
   //    },
   //    tabletDropdown: {
   //       whenExpanded: false,
   //       whenDefault: false,
   //    },
   //    dropDownIcon: '',
   // },
   {
      href: '',
      InnerIcon: FeedbackButton,
      HomeIcon: FeedbackButton,
      text: <Translate id="feedback:submit feedback" />,
      item: {
         isPage: false,
      },
      primaryLinkDefault: false,
      primaryLinkExpanded: false,
      tabletPrimaryLinkDefault: false,
      tabletPrimaryLinkExpanded: false,
      fullScreenDropdown: {
         whenExpanded: false,
         whenDefault: false,
      },
      tabletDropdown: {
         whenExpanded: false,
         whenDefault: true,
      },
      dropDownIcon: '/images/common/happy.svg',
   },
];
