import { Fragment, useState } from 'react';
import dynamic from 'next/dynamic';
import { SubmitFeedback } from '~/svgs/feedback';
import { Translate } from '~/i18n';
import { isJarir } from '~/utils/theme';

const FeedbackContent = dynamic(() => import('./feedback'), { ssr: false });

const FeedbackButton = () => {
   const [open, setOpen] = useState(false);

   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   return (
      <Fragment>
         {isJarir ? (
            <button
               className="flex flex-col justify-center items-center focus:outline-none"
               onClick={handleOpen}>
               <div className="text-sm">
                  <Translate id="feedback:feedback" />
               </div>
            </button>
         ) : (
            <button
               className="flex flex-col justify-center items-center focus:outline-none"
               onClick={handleOpen}>
               <SubmitFeedback />
               <div className="text-xs">
                  <Translate id="feedback:feedback" />
               </div>
            </button>
         )}

         <FeedbackContent open={open} handleClose={handleClose} />
      </Fragment>
   );
};

export default FeedbackButton;
