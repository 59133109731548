import { FC, Fragment, useState } from 'react';
import Header from './header';
import Footer from './footer';
import { useEffect } from 'react';
import jsCookie from 'js-cookie';
import Axios from '~/utils/axios';
import { useAuth } from '~/auth';
import { useTranslate } from '~/i18n';
import { Toaster } from 'react-hot-toast';
import { UserType } from '~/interfaces/user';
// import OTPModal from './otp-modal';
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/*                                 MAIN LAYOUT                                */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */

interface LayoutProps {
   langHref?: string;
   hideHeader?: boolean;
   hideLayout?: boolean;
   smallFooter?: boolean;
   transparentNavbar?: boolean;
   hideSearchbar?: boolean;
}

const Layout: FC<LayoutProps> = ({
   children,
   langHref,
   hideHeader,
   smallFooter,
   hideLayout,
   transparentNavbar,
   hideSearchbar,
}) => {
   const { authenticated, user, updateUser, logout } = useAuth<UserType>();
   const [error, setError] = useState(false);
   const { lang } = useTranslate();
   useEffect(() => {
      const getUser = async () => {
         if (authenticated && !user && !error) {
            try {
               const locale = lang?.split('-')[1];
               // ! CHANGE TO TOKEN !!!
               const user_id = jsCookie.get('user_id');
               const { data } = await Axios.post('hyapp/user/profile', {
                  lang: locale,
                  user_id,
               });
               if (data.error) {
                  setError(true);
                  logout();
                  return;
               }
               updateUser({ ...data?.data, wishlist: data?.data?.wishlist_venues || [] });
            } catch (error) {
               setError(true);
               console.error(error);
            }
         }
      };
      if (!hideLayout) {
         getUser();
      }
   }, [authenticated, user, lang, updateUser, error, logout, hideLayout]);

   // const SMS_NOT_VERIFIED = user?.sms_verified === 'No';

   // const otpModal = authenticated && SMS_NOT_VERIFIED ? <OTPModal /> : null;

   if (hideLayout)
      return (
         <Fragment>
            {/* {otpModal} */}
            {children}
         </Fragment>
      );

   return (
      <Fragment>
         {/* {otpModal} */}
         <Toaster position="top-center" />
         {!hideHeader && (
            <Header
               hideSearchbar={hideSearchbar}
               langHref={langHref}
               transparentNavbar={transparentNavbar}
            />
         )}
         <main>{children}</main>
         <Footer smallFooter={smallFooter} />
      </Fragment>
   );
};

export default Layout;
