import { countries } from 'countries-list';
import cn from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import useOnClickOutside from '~/hooks/on-click-outside';
import { useEffect } from 'react';
import { ArrowDownIcon } from '~/svgs/common';
import { FlagIcon } from 'react-flag-kit';

type SelectParam = {
   code: string;
   phone: string;
};

type PhonePickerProps = React.HTMLAttributes<HTMLInputElement> & {
   // eslint-disable-next-line no-unused-vars
   onCountrySelected?: (params: SelectParam) => any;
   showTop?: boolean;
   disabled?: boolean;
};
const PhonePicker = ({
   className,
   showTop,
   disabled,
   onCountrySelected,
   ...props
}: PhonePickerProps) => {
   const [open, setOpen] = useState(false);
   const [search, setSearch] = useState('');
   const ref = useRef(null);
   const inputRef = useRef<HTMLInputElement>(null);
   const firstItemRef = useRef<HTMLLIElement>(null);
   const [selectedCountryCode, setSelectedCountryCode] = useState<any>('SA');

   const list = useMemo(() => {
      const s = search.toLowerCase();
      return Object.entries(countries).filter(([code, country]) => {
         if (country.name === 'Israel' || country.name === 'Western Sahara') return false;
         const phone = `( +${country.phone.toLocaleLowerCase()} )`;
         return (
            code.toLocaleLowerCase().includes(s) ||
            phone.includes(s) ||
            country.name.toLocaleLowerCase().includes(s)
         );
      });
   }, [search]);

   useEffect(() => {
      if (firstItemRef.current && open) {
         // firstItemRef.current.focus();
      }
   }, [firstItemRef, list, open]);

   const itemClicked = ({ e, code, phone }: { e: any; code: string; phone: string }) => {
      e?.stopPropagation();
      if (inputRef.current) inputRef.current.value = phone;
      setSearch('');
      setOpen(false);
      onCountrySelected && onCountrySelected({ code, phone });
      setSelectedCountryCode(code);
   };

   const shiftSibling = (e: React.KeyboardEvent<HTMLLIElement>) => {
      const prevSibling = e.currentTarget.previousElementSibling as HTMLLIElement;
      const nextSibling = e.currentTarget.nextElementSibling as HTMLLIElement;
      if (e.key === 'ArrowUp' && prevSibling) prevSibling.focus();
      if (e.key === 'ArrowDown' && nextSibling) nextSibling.focus();
   };

   const handleBlurEvent = (e?: any) => {
      const value = inputRef.current?.value;
      if (value && document.activeElement?.id === 'phone-picker') {
         const clone = list.filter(([, country]) => country.phone.includes(value));

         if (clone.length === 1) {
            const item = clone[0];
            itemClicked({
               code: item[0],
               e,
               phone: item[1].phone,
            });
         } else {
            itemClicked({
               e,
               phone: '',
               code: '',
            });
         }
      }
   };

   useOnClickOutside(ref, () => {
      setOpen(false);
      handleBlurEvent();
   });

   const openMenu = () => {
      if (disabled) return;
      setOpen(true);
   };
   return (
      <div ref={ref} className="h-full">
         <label
            htmlFor="phone-picker"
            className="relative w-full h-full bg-gray-100 text-gray-400 focus-within:text-gray-600  ltr:rounded-l-default rtl:rounded-r-default flex items-center">
            <FlagIcon code={selectedCountryCode} size={28} className="ltr:ml-2 rtl:mr-2" />
            <input
               id="phone-picker"
               onFocus={openMenu}
               onClick={openMenu}
               // onBlur={handleBlurEvent}
               onChange={e => setSearch(e.currentTarget.value ?? '')}
               autoCorrect="off"
               autoComplete="none"
               autoSave="off"
               disabled={disabled}
               defaultValue="966"
               ref={inputRef}
               className={cn(
                  'w-full h-full px-2 bg-gray-100 focus:outline-none ltr:rounded-l-default rtl:rounded-r-default text-md',
                  className,
                  disabled && 'bg-black cursor-not-allowed'
               )}
               maxLength={3}
               {...props}
            />
            <ArrowDownIcon
               className={cn(
                  'w-6 ltr:mr-2 rtl:ml-2 transform transition-all duration-150 text-gray-400 opacity-40',
                  open && 'rotate-180'
               )}
               onClick={(e: any) => {
                  e?.preventDefault();
                  setOpen(!open);
               }}
            />
         </label>
         {open && (
            <ul
               role="menu"
               aria-labelledby="options-menu"
               className={cn(
                  'absolute z-40 max-w-full py-4 overflow-y-scroll bg-white rounded-b max-h-64 transform ltr:left-0 rtl:right-0 shadow-base',
                  showTop ? 'bottom-full' : 'top-full'
               )}>
               {list.map(([code, { phone, name }], idx) => (
                  <li
                     ref={idx === 0 ? firstItemRef : null}
                     key={code}
                     onKeyUp={e => {
                        if (e.key === 'Space' || e.key === 'Enter') itemClicked({ e, phone, code });
                        shiftSibling(e);
                     }}
                     className="px-4 py-2 hover:bg-gray-100 focus:bg-gray-100"
                     role="menuitem"
                     onClick={e => {
                        itemClicked({ e, phone, code });
                     }}
                     tabIndex={-1}>
                     <span>
                        {name} ( +{phone} )
                     </span>
                  </li>
               ))}
            </ul>
         )}
      </div>
   );
};

export default PhonePicker;
