import { Mixpanel } from '../init';

export const navbarExpTabEvent = () => {
   Mixpanel.track('Navbar - Exp Tab');
};

export const navbarHYBlogTabEvent = () => {
   Mixpanel.track('Navbar - HY Blog Tab');
};

export const navbarAboutUsTabEvent = () => {
   Mixpanel.track('Navbar - About Us Tab');
};

export const navbarHYBusinessTabEvent = () => {
   Mixpanel.track('Navbar - HY Business Tab');
};

export const navbarLanguageSwitchEvent = () => {
   Mixpanel.track('Navbar - Language Switch Btn');
};

export const navbarMyBookingsBtnEvent = () => {
   Mixpanel.track('Navbar - My Bookings Btn');
};

export const navbarViewProfileBtnEvent = () => {
   Mixpanel.track('Navbar - View Profile Btn');
};

export const navbarVouchersCreditBtnEvent = () => {
   Mixpanel.track('Navbar - Vouchers Credit Btn');
};

export const navbarGetDiscountBtnEvent = () => {
   Mixpanel.track('Navbar - Get Discount Btn');
};

export const navbarLogoutBtnEvent = () => {
   Mixpanel.track('Navbar - Logout Btn');
};

export const navbarLogoutConfirmBtn = () => {
   Mixpanel.track('Navbar - Logout Confirm Btn');
};
