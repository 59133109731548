import { Translate, useTranslate } from '~/i18n';
import { AngelLeftIcon, AngelRightIcon, CloseIconDark } from '~/svgs/common';
import Input from '~/components/common/input';
import Button from '../button';
import Axios from '~/utils/axios';
import { useState } from 'react';
import { useAuth } from '~/auth';
import { UserType } from '~/interfaces/user';
import { useCallback, useEffect, useRef } from 'react';
import { sentryCaptureException } from '~/errors/sentry';

type TextfieldBodyProps = {
   title: string;
   emote: string;
   handleClose: () => void;
   // eslint-disable-next-line no-unused-vars
   updateHeight: (e: HTMLDivElement) => void;
   // eslint-disable-next-line no-unused-vars
   setBody: (feedback: string, emote: string) => void;
};

const TextfieldBody = ({
   title,
   emote,
   handleClose,
   updateHeight,
   setBody,
}: TextfieldBodyProps) => {
   const { dir, translate } = useTranslate();
   const [textareaInput, setTextareaInput] = useState('');
   const { user } = useAuth<UserType>();
   const ref = useRef<any>();

   const update = useCallback(() => {
      updateHeight(ref.current);
   }, [updateHeight]);

   useEffect(() => {
      if (ref) {
         update();
      }
   }, [ref, update]);

   const sendFeedback = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setBody('submit', '');

      const values = {
         message: textareaInput,
         feedback_option: title,
         feedback_emoji: emote,
         email: user?.email,
         id: user?.user_id,
      };
      try {
         await Axios.post('https://app.halayalla.com/api/send_feedback', values);
      } catch (error: any) {
         sentryCaptureException(error, { extra: values });
         console.error(error);
      }
   };

   return (
      <div ref={ref} className="flex flex-col p-6">
         <div className="flex self-start justify-between w-full mb-4">
            <button
               onClick={() => setBody(emote, '')}
               className="flex items-center text-gray-500 ltr:-ml-2 rtl:-mr-2 hover:opacity-50 focus:opacity-50 focus:outline-none">
               {dir === 'ltr' ? <AngelLeftIcon /> : <AngelRightIcon />}
               <span>
                  <Translate id="common:back" />
               </span>
            </button>
            <CloseIconDark
               onClick={handleClose}
               className="p-px border rounded-full cursor-pointer hover:opacity-60 focus:opacity-60 border-primary-disabled"
            />
         </div>
         <div className="mb-8 text-xl font-bold">{title}</div>
         {/* TODO: add form submission function that is defined above */}
         <form onSubmit={sendFeedback}>
            <Input
               type="textarea"
               rows={5}
               className="mb-5 bg-gray-100 resize-none"
               labelClassName="text-sm"
               label={translate({ id: 'feedback:please describe' })}
               value={textareaInput}
               onChange={e => setTextareaInput(e.currentTarget.value)}
            />
            <Button btnType="submit" className="w-full">
               <Translate id="feedback:submit" />
            </Button>
         </form>
      </div>
   );
};

export default TextfieldBody;
