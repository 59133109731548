import { isJarir } from './../theme';
import mixpanel from 'mixpanel-browser';

const key = isJarir
   ? process.env.NEXT_PUBLIC_MIXPANEL_TOKEN_JARIR
   : process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

export const initMixpanel = () => {
   if (process.env.NEXT_PUBLIC_ENV === 'production') {
      try {
         mixpanel.init(key, {
            debug: process.env.NEXT_PUBLIC_ENV !== 'production',
         });
      } catch (error) {
         //
      }
   }
};

const actions = {
   identify: (id: any) => {
      try {
         if (process.env.NEXT_PUBLIC_ENV === 'production') mixpanel.identify(id);
      } catch (error) {
         //
      }
   },
   track: (name: any, props?: any) => {
      try {
         if (process.env.NEXT_PUBLIC_ENV === 'production') mixpanel.track(name, props);
      } catch (error) {
         //
      }
   },
   reset: () => {
      try {
         if (process.env.NEXT_PUBLIC_ENV === 'production') mixpanel.reset();
      } catch (error) {
         //
      }
   },
   people: {
      set: (props: any) => {
         try {
            if (process.env.NEXT_PUBLIC_ENV === 'production') mixpanel.people.set(props);
         } catch (error) {
            //
         }
      },
   },
};

export const Mixpanel = actions;
