import { useCallback, useEffect, useState } from 'react';
// import OTPInput from 'react-otp-input';
import { Translate, useTranslate } from '~/i18n';
import Button from '~/components/common/button';
import Axios from '~/utils/axios';
import Loading from 'components/common/loaders/loader';
import { AngelLeftIcon, AngelRightIcon } from '~/svgs/common';
import { useForm } from 'react-hook-form';
import Input, { PhonePicker } from 'components/common/input';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { JarirUserType } from '~/interfaces/user';
import { useAuth } from '~/auth';
import jsCookie from 'js-cookie';
import Timer from '~/components/common/timer';
import cn from 'classnames';
import OtpInput from '~/components/common/otp-input';
import { isJarir } from '~/utils/theme';
import {
   mixpanelIdentify,
   signupNextBtnEvent,
   signupNextBtnFailedEvent,
   signupNextBtnSuccessEvent,
   signupPopUpViewEvent,
   signupResendOTPBtnEvent,
   signupResendOTPBtnFailedEvent,
   signupResendOTPBtnSuccessEvent,
   signupVerifyBtnEvent,
   signupVerifyBtnFailedEvent,
   signupVerifyBtnSuccessEvent,
} from '~/utils/mixpanel-events/signup-login';
import phone from 'phone';
import { generateMd5 } from '~/utils/md5';
import useRecaptcha from '~/hooks/useRecaptcha';
import { sentryCaptureException, sentryCaptureMessage, sentrySetUser } from '~/errors/sentry';

type SubmitObject = {
   country_code: string;
   mobile: string;
};
interface FormsProps {
   openOtp: (active: boolean) => void;
   onLogin?: (data: any) => any;
   redirectBanner?: (active: boolean) => void;
   isBookingPage?: boolean;
   isTickets?: boolean;
   order: any;
   preventRedirect?: boolean;
   otpMode?: boolean;
   modalTitle?: string;
   onPaymentFailed?: (data: any) => void;
}
const Forms = ({
   openOtp,
   isBookingPage,
   order,
   preventRedirect,
   onLogin,
   otpMode,
   modalTitle,
   onPaymentFailed,
}: FormsProps) => {
   const { user } = useAuth();
   const router = useRouter();
   const { translate, lang, dir } = useTranslate();
   const { login } = useAuth<JarirUserType>();
   const [otp, setOtp] = useState('');
   const [mobile, setMobile] = useState('');
   const [countryCode, setCountryCode] = useState('');
   const [isOtp, setIsOtp] = useState(otpMode || false);
   const [loading, setLoading] = useState(false);
   const [otpLoading, setOtpLoading] = useState(false);
   const [mobileError, setMobileError] = useState('');
   const [error, setError] = useState('');
   const [waitOTP, setWaitOTP] = useState(true);
   const [isWhatsapp, setIsWhatsapp] = useState(false);

   const { executeRecaptcha, renderV2Captcha, removeV2Captcha, isSpammyCountry } = useRecaptcha({
      v2elementId: 'v2-captcha-section',
   });

   //SIGNUP
   const { register, handleSubmit, getValues, errors, setValue } = useForm<SubmitObject>({
      mode: 'onChange',
      defaultValues: {
         country_code: '966',
      },
   });

   const values = getValues();

   useEffect(() => {
      if (otpMode && user) {
         const p = phone(user?.cell_number);
         const countryCode = String(p.countryCode);
         const mo = String(p.phoneNumber);
         setCountryCode(countryCode);
         setMobile(mo);
         resendCode(countryCode, mo);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [otpMode]);

   const submitHandler = async (obj: SubmitObject) => {
      signupNextBtnEvent(obj.mobile, obj.country_code);
      sentryCaptureMessage('[Login] Mobile Next', { extra: obj, tags: { journey: 'auth' } });
      try {
         setLoading(true);
         if (mobileError) {
            setMobileError('');
         }

         const signature = generateMd5(`${obj.country_code}${obj.mobile}`);

         const captcha = await executeRecaptcha(+obj.country_code);
         const { data } = await Axios.post(
            isJarir ? `hyapp/jarir/guest-booking/register-mobile` : 'hyapp/auth/v2/signup',
            { ...obj, captcha, signature, app_source: isJarir ? 'jarir' : 'hy' }
         );

         if (data.status == 'Success' || data.original?.status === 'Success') {
            setCountryCode(obj.country_code);
            setMobile(data?.data?.mobile || data?.original?.data?.mobile || '');
            setIsOtp(true);
            const viaWhatsapp = data?.original?.message?.is_whatsapp;
            setIsWhatsapp(!!viaWhatsapp);
            signupNextBtnSuccessEvent(data?.data?.mobile || data?.original?.data?.mobile || '');
         } else {
            throw new Error(data?.error?.cell_number || data?.original?.error?.description);
         }
      } catch (error: any) {
         const msg = error?.message || "Something's wrong";
         signupNextBtnFailedEvent(obj.mobile, obj.country_code, msg);
         setMobileError(msg);
         sentryCaptureException(error, { extra: obj, tags: { journey: 'auth' } });
      } finally {
         setLoading(false);
      }
   };

   //OTP
   const OTPsubmitHandler = async () => {
      signupVerifyBtnEvent(mobile);
      sentryCaptureMessage('[Login] OTP', { extra: { mobile }, tags: { journey: 'auth' } });
      try {
         if (otpLoading) return;
         setOtpLoading(true);
         setError('');
         const captcha = await executeRecaptcha();
         const { data } = await Axios.post(
            isJarir ? 'hyapp/jarir/guest-booking/verify-mobile-otp' : 'hyapp/auth/v2/verify-otp',
            {
               mobile: mobile,
               code: otp,
               captcha,
               app_source: isJarir ? 'jarir' : 'hy',
            }
         );

         if (data.status === 'Success') {
            setOtpLoading(false);
            openOtp(false);
            setIsOtp(false);
            onLogin?.(data.data);
            // onLogin && onLogin(data?.data?.user);
            const user = data.data.user;
            jsCookie.set('user_id', user?._id || user?.user_id);
            login({
               token: user.access_token,
               user: { ...user, new_user: data.data.new_user || false },
               noRedirect: true,
            });
            if (order) {
               order.user_id = user?.user_id || user?._id;
            }
            sentrySetUser({ id: user.cell_number, email: user.email, name: user.name });
            sentryCaptureMessage('[Login] OTP Success', {
               extra: { mobile },
               tags: { journey: 'auth' },
            });
            if (!isBookingPage) !preventRedirect && router.push(`/${lang}/profile/bookings`);
            mixpanelIdentify(user);
            signupVerifyBtnSuccessEvent(mobile || user.cell_number);
         } else {
            throw new Error(translate({ id: 'validation:incorrect otp' }));
         }
      } catch (e: any) {
         setError(e.message);
         signupVerifyBtnFailedEvent(mobile, e.message);
         setOtpLoading(false);
         onPaymentFailed?.(e?.response);
         sentryCaptureException(e, { extra: { mobile }, tags: { journey: 'auth' } });
      }
   };

   useEffect(() => {
      register('country_code', {
         required: translate({ id: 'auth:required field' }),
      });
   }, [register, translate]);

   const resendCode = useCallback(
      async (c?: string, m?: string) => {
         signupResendOTPBtnEvent(mobile);
         sentryCaptureMessage('[Login] Resent OTP', {
            extra: { mobile },
            tags: { journey: 'auth' },
         });
         setWaitOTP(true);

         const mob = mobile || m;
         const cc = countryCode || c;
         const cleanMobile = mob?.replace(`+${cc}`, '');
         try {
            const signature = generateMd5(`${cc}${cleanMobile}`);

            const captcha = await executeRecaptcha();
            const { data } = await Axios.post(
               isJarir ? 'hyapp/jarir/guest-booking/register-mobile' : '/hyapp/auth/v2/signup',
               {
                  mobile: cleanMobile,
                  country_code: cc,
                  resend: true,
                  captcha,
                  signature,
               }
            );
            if (data.status === 'Success' || data.original?.status === 'Success') {
               const viaWhatsapp = data?.original?.message?.is_whatsapp;
               setIsWhatsapp(!!viaWhatsapp);
               signupResendOTPBtnSuccessEvent(mobile);
            } else {
               throw new Error(data?.error?.cell_number || data?.original?.error?.description);
            }
         } catch (error: any) {
            signupResendOTPBtnFailedEvent(mobile, error.message);
            const msg = error?.message || "Something's wrong";
            setMobileError(msg);
            sentryCaptureException(error, {
               extra: { countryCode: cc, mobile, resend: true },
               tags: { journey: 'auth' },
            });
         }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [mobile, countryCode]
   );

   useEffect(() => {
      signupPopUpViewEvent();
   }, []);

   return (
      <div>
         <div>
            {!isOtp ? (
               <div>
                  {isBookingPage && (
                     <button
                        onClick={() => openOtp(false)}
                        className="flex mb-5 text-gray-deminished">
                        {dir == 'ltr' ? <AngelLeftIcon /> : <AngelRightIcon />}
                        <Translate id="jarirauth:back to booking" />
                     </button>
                  )}
                  <div className="pb-8 text-2xl font-semibold">
                     {modalTitle ? (
                        modalTitle
                     ) : isBookingPage ? (
                        <Translate id="jarirauth:complete your booking" />
                     ) : isJarir ? (
                        <Translate id="jarirauth:signup modal title" />
                     ) : (
                        <Translate id="auth:default auth title" />
                     )}
                  </div>
                  <form onSubmit={handleSubmit(submitHandler)}>
                     <div className="w-full mb-6">
                        <Input
                           type="text"
                           label={translate({ id: 'jarirauth:enter number' })}
                           className="bg-gray-100 rounded-default ltr:!border-l rtl:!border-r"
                           groupClassName=""
                           placeholder="XXXXXXXXX"
                           id="mobile"
                           name="mobile"
                           inputMode="numeric"
                           autoCorrect="off"
                           autoComplete="none"
                           autoSave="off"
                           inputWrapperClassName="relative"
                           innerRef={register({
                              required: translate({ id: 'auth:required field' }),
                           })}
                           error={mobileError}
                           valid={!!values.mobile}
                           prependClassName={classNames(
                              !!errors.country_code && 'border-primary-error',
                              !!values.country_code && 'border-primary-success'
                           )}
                           prepend={
                              <div className="w-24 h-full">
                                 <PhonePicker
                                    placeholder="XXX"
                                    onCountrySelected={({ phone }) => {
                                       setValue('country_code', phone, {
                                          shouldValidate: true,
                                       });
                                       if (isSpammyCountry(+phone)) {
                                          renderV2Captcha();
                                       } else {
                                          removeV2Captcha();
                                       }
                                    }}
                                 />
                              </div>
                           }
                        />
                        <div className="text-xs text-gray-400 ">
                           <Translate id="jarirauth:verification code" />
                        </div>
                     </div>
                     <div className="text-center" id="v2-captcha-section"></div>
                     <Button
                        btnType="submit"
                        id="mobile-login"
                        className={classNames(
                           'w-full mt-5 border-0',
                           isJarir ? 'bg-jarir-primary' : 'bg-primary-color'
                        )}
                        disabled={loading || !values.mobile}>
                        {loading ? <Loading color="white" /> : <Translate id="common:next" />}
                     </Button>

                     <div className="pt-3 text-xs text-center text-gray-400">
                        <Translate id="auth:on next you agrees to our" />{' '}
                        <a
                           target="_blank"
                           rel="noreferrer noopener"
                           className="text-blue-500 underline"
                           href={
                              isJarir
                                 ? `https://tickets.jarir.com/${lang}/terms-conditions`
                                 : 'https://halayalla.com/terms.php'
                           }>
                           <Translate id="auth:terms and conditions" />
                        </a>
                     </div>
                  </form>
               </div>
            ) : (
               <div>
                  <button
                     onClick={() => setIsOtp(false)}
                     className="flex mb-5 text-gray-deminished">
                     {dir == 'ltr' ? <AngelLeftIcon /> : <AngelRightIcon />}
                     <Translate id="jarirauth:change mobile number" />
                  </button>
                  <div className="text-2xl font-bold">
                     <Translate id="jarirauth:verify your mobile number" />
                  </div>
                  <div className="mb-4 text-sm text-gray-deminished"></div>
                  <div className="mb-2 text-xs text-center">
                     <Translate
                        id="jarirauth:enter the verification code"
                        values={{
                           number: mobile,
                        }}
                     />
                     {isWhatsapp && <Translate id="jarirauth:via whatsapp" />}
                  </div>
                  <div className="flex flex-col items-center justify-center mb-8 ">
                     <OtpInput
                        isInputNum
                        value={otp}
                        numInputs={4}
                        onChange={(otp: string) => setOtp(otp)}
                        className="px-2 text-primary-color"
                        focusStyle={{
                           border: '1px solid #212529',
                           boxShadow: 'none',
                        }}
                        inputStyle={{
                           width: '40px',
                           height: '38px',
                           fontSize: '1rem',
                           borderRadius: 4,
                           border: '1px solid rgba(229, 231, 235)',
                           backgroundColor: 'rgb(243, 244, 246)',
                        }}
                     />
                     {error && <div className="mt-2 text-sm text-primary-error">{error}</div>}
                  </div>
                  <div className="mb-6 text-sm text-center">
                     <span className="text-gray-deminished">
                        <Translate id="jarirauth:didn’t receive it" />
                     </span>
                     <button
                        onClick={() => resendCode()}
                        disabled={waitOTP}
                        id="resend-otp"
                        className={cn(
                           'text-jarir-primary px-2 font-bold ',
                           waitOTP && 'opacity-70 cursor-not-allowed'
                        )}>
                        <Translate id="auth:resend code" />
                        {waitOTP && (
                           <Timer
                              time={60}
                              onTimeFinished={() => setWaitOTP(false)}
                              component={({ time }) => <span>({time})</span>}
                           />
                        )}
                     </button>
                  </div>
                  <Button
                     id="submit-otp"
                     disabled={otp.length !== 4}
                     onClick={OTPsubmitHandler}
                     className={classNames(
                        'w-full py-3 border-0 rounded-xl',
                        isJarir ? 'bg-jarir-primary' : 'bg-primary-color'
                     )}>
                     {otpLoading ? (
                        <Loading color="white" size={20} />
                     ) : (
                        <Translate id="auth:verify" />
                     )}
                  </Button>
               </div>
            )}
         </div>
      </div>
   );
};

export default Forms;
